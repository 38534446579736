import React, { useEffect} from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import 'react-tabs/style/react-tabs.css';


import 'react-tabs/style/react-tabs.css';
import { MasterLayout } from '../_metronic/layout/MasterLayout'


interface IdiomaParams {
    id: string;
}

type IdiomaProps = RouteComponentProps<IdiomaParams>;

export const Idioma: React.FC<IdiomaProps> = (props: IdiomaProps) => {
    const id = props.match.params.id;
    const history = useHistory();
    const [t,i18n] = useTranslation("global");
    let idioma : any = null;
    idioma= sessionStorage.getItem('idioma');
    if(idioma == null){
        idioma='ESPAÑOL';
    }
    
    useEffect(() => {  
        if(idioma.localeCompare("INGLÉS") === 0){
            i18n.changeLanguage("en");
        }else if(idioma.localeCompare("FRANCÉS") === 0){
            i18n.changeLanguage("fr");           
        }else if(idioma.localeCompare("ESPAÑOL") === 0){
            i18n.changeLanguage("es");         
        }

        const storage = sessionStorage.getItem('sessionLogin');
        if(!storage) {
            history.push('/login');
        } else {
            if(!JSON.parse(storage)) {
                history.push('/login');
            } else {
   
            }
        }
    }, [id, history]);
 

    const idiomaHandler = ((idi:any) => {
        if(idi.localeCompare("INGLÉS") === 0){
            i18n.changeLanguage("en");
        }else if(idi.localeCompare("FRANCÉS") === 0){
            i18n.changeLanguage("fr");           
        }else if(idi.localeCompare("ESPAÑOL") === 0){
            i18n.changeLanguage("es");         
        }
        
        sessionStorage.setItem('idioma',idi);
        idioma = idi;
        return undefined;
    });  
    return(
        <MasterLayout >
        <div className='card card card-xl-stretch mb-xl-8' style={{marginLeft:'3%', marginRight:'3%'}}>
            <div className="row" style={{marginLeft:'40%'}}>   
              <h2>{t("languages.selectedLanguage")}</h2>
              <br></br>
                <div className="col-9"  style={{marginTop:'4%',marginLeft:'5%'}}>            
                    <div className="navi-item">
                     {
                        idioma==="INGLÉS" && 
                            <a  className="navi-link">
                                <span className="symbol symbol-20 mr-3">
                                    <img src="https://preview.keenthemes.com/metronic/theme/html/demo1/dist/assets/media/svg/flags/226-united-states.svg" alt=""/>
                                </span>
                                <br></br>
                                <span className="navi-text">{t("languages.english")}</span>
                            </a>
                    }
                    {
                        idioma==="ESPAÑOL" && 
                                <a  className="navi-link">
                                    <span className="symbol symbol-20 mr-3">
                                        <img src="https://preview.keenthemes.com/metronic/theme/html/demo1/dist/assets/media/svg/flags/128-spain.svg" alt=""/>
                                    </span>
                                    <br></br>
                                    <span className="navi-text">{t("languages.spanish")}</span>
                                </a>
                    }
                    {
                        idioma==="FRANCÉS" && 
                                <a  className="navi-link">
                                    <span className="symbol symbol-20 mr-3">
                                        <img src="https://preview.keenthemes.com/metronic/theme/html/demo1/dist/assets/media/svg/flags/195-france.svg" alt=""/>
                                    </span>
                                    <br></br>
                                    <span className="navi-text">{t("languages.french")}</span>
                                </a>
                    }
				</div>
            </div> 
        </div>    
       <br></br> <br></br> <br></br>
        <div  style={{marginLeft:'40%'}} className="col-sm-6">
            <h2>{t("languages.select")}</h2>
            <br></br>
       </div> 
       <div className="row" style={{marginLeft:"23%"}}>
         <div className="col-3">
            <div   className="navi-item">
                <a  className="navi-link" onClick={()=>idiomaHandler("INGLÉS")}>
                    <span className="symbol symbol-20 mr-3">
                        <img src="https://preview.keenthemes.com/metronic/theme/html/demo1/dist/assets/media/svg/flags/226-united-states.svg" alt=""/>
                    </span>
                    <br></br>
                    <span className="navi-text">{t("languages.english")}</span>
                </a>
            </div>                                           
        </div>
    <div className="col-3">
    <div className="navi-item">
        <a  className="navi-link" onClick={()=>idiomaHandler("ESPAÑOL")} >
            <span className="symbol symbol-20 mr-3">
                <img src="https://preview.keenthemes.com/metronic/theme/html/demo1/dist/assets/media/svg/flags/128-spain.svg" alt=""/>
            </span>
            <br></br>
            <span className="navi-text">{t("languages.spanish")}</span>
        </a>
    </div>                                        
    </div>
    <div className="col-3">
        <div className="navi-item">
            <a  className="navi-link" onClick={()=>idiomaHandler("FRANCÉS")}>
                <span className="symbol symbol-20 mr-3">
                    <img src="https://preview.keenthemes.com/metronic/theme/html/demo1/dist/assets/media/svg/flags/195-france.svg" alt=""/>
                </span>
                <br></br>
                <span className="navi-text">{t("languages.french")}</span>
            </a>
        </div>
    </div>   
    </div>
    </div>  
    <br></br> 
    <br></br>  
    <br></br>  
    <br></br>  
    <br></br>                
    <br></br>  
    <br></br>  
    <br></br>  
    <br></br>  
        </MasterLayout> 
    );

}