import React, {ChangeEvent, useState} from "react";

interface ItemProps {
    name: string
    value: string
    placeholder: string
    required: boolean
    onChange(event: ChangeEvent<HTMLInputElement>)
    type: string
    readOnly: boolean
    t?: any
    isPasarelaOption?: boolean
    showEye?: boolean
}

export const FormItem = ({name, value, placeholder, required, onChange, type, readOnly, t, isPasarelaOption,showEye}: ItemProps) => {

  const [show, setShow] = useState<boolean>(false)

  const changeShow = () => {
    setShow(!show)
  }

    return (
        <>
            <div className='row mb-6'>
              <div className="col-lg-4">
                <h2 style={{color:'#0a3154'}}>{placeholder}</h2>
              </div>
             {/* <label className={`col-lg-4 col-form-label ${req} fw-bold fs-6`}>{placeholder}</label>*/}

              <div className='col-lg-8 fv-row'>
                  {
                      readOnly &&
                      <input
                      type={type}
                      className='form-control form-control-lg form-control-solid' 
                      placeholder={isPasarelaOption ? '************' : placeholder} readOnly name={name} value={value} onChange={onChange} style ={{backgroundColor: '#fffff',padding:"20px 20px 25px" }}
                    />
                  }
                  {
                      !readOnly && 
                      <>
                      <div>
                        <input
                        type={!show ? type : 'text'}
                        className='form-control form-control-lg form-control-solid' style={{backgroundColor: '#f5f5f5', padding: '20px 20px 25px;'}}
                        placeholder={isPasarelaOption ? '************' : placeholder} name={name} value={value} onChange={onChange}
                      />
                      {
                        isPasarelaOption && showEye &&
                        <button className={!show ? "bi bi-eye-slash" : 'bi-eye'} style={{backgroundColor:'white'}} onClick={changeShow}></button>
                      }
                    </div>

                      {
                        name === 'TERMINAL_GREENPAY' && t &&
                        <div className='col-lg-8 fv-row'>
                          <span style={{paddingLeft: '15px', color: 'mediumblue'}}>{t("clientsData.terminalGreenpay")}</span>
                        </div>
                      }
                      {
                        name === "UUID_SERVICE_PAYNOPAIN" && t &&
                        <div className='col-lg-12 fv-row'>
                          <span style={{paddingLeft: '15px', color: 'mediumblue'}}>{t("clientsData.uuid_service_paynopain")}</span>
                        </div>                        
                      }


                    </>
                  }

                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'></div>
                  </div>

              </div>
            </div>
        </>
    )
}
