import React, { useEffect, useState} from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import {  getExperienciaFiltrada, subirImagen} from './requests/RequestMaker'
import {useTranslation} from 'react-i18next';

import 'react-tabs/style/react-tabs.css';
import { MasterLayout } from '../_metronic/layout/MasterLayout';
import {  updateExperiencia, getClienteIDToken } from './requests/RequestMaker';
import Swal from 'sweetalert2';
import Experiencia from './models/Experiencia';
import TabNav from './Widgets/TabNavOwn';
import Tab from './Widgets/TabOwn';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import { EditorOwn } from './Widgets/EditorOwn';
import { Spinner } from 'reactstrap';
import { HtmlTooltip } from './Widgets/HtmlTooltip';

interface UpdateExperienciaParams {
    idClient: string;
    id: string;
}

type UpdateExperienciaProps = RouteComponentProps<UpdateExperienciaParams>;

export const UpdateExperiencia: React.FC<UpdateExperienciaProps> = (props: UpdateExperienciaProps) => {

    const id = +props.match.params.id;
    const history = useHistory();
    let lang = window.navigator.language;
    lang = lang.substring(0,2);

    const initials_imgs = {
        image1: "", 
        image2: "",
        image3: "",
        image4: "",
        image5: "",
        image6: "",
        image7: "",
        image8: "",
        image9: "",
        image10: "",
        image11: "",
        image12: "",
        image13: "",
        image14: "",
        image15: "",
    }

    const [clientID,setClientID] = useState<number>();
    const [name,setName] = useState<string>("");
    const [experiencia, setExperiencia] = useState<Experiencia>(undefined);
    const [experienciaRecib, setExperienciaRecib] = useState<Experiencia>(undefined);
    const [images, setImages] = useState<any>(initials_imgs);
    const [t,i18n] = useTranslation("global");
    const [existe,setExiste] = useState<boolean>(false);
    const [idOpL, setIdOpL] = useState<number>(undefined);
    const [loadingImage, setLoadingImage] = useState<boolean>(false)
    const [indexChange, setIndexChange] = useState<number>(0);
    const [bloques, setBloques] = useState<any>({
        "es": undefined,
        "en": undefined
    })
    let habilitarActualizarEs = false;
    let habilitarActualizarEn = false;

    //let imgs = [images.image1, images.image2, images.image3, images.image4, images.image5, images.image6, images.image7, images.image8, images.image9, images.image10, images.image11, images.image12, images.image13, images.image14, images.image15];
    //let imgs = [images.image1]
    const [imgs, setImgs] = useState<any>([""])
    let idioma : any = null;
    idioma= sessionStorage.getItem('idioma');
    let token = sessionStorage.getItem('token');
    // TODO array con idiomas a codigo duro, posteriormente será a través de una petición
    let idiomas = [
        {text: 'Español (ES)', value: 'es'}, 
        {text: 'Inglés (EN)', value: 'en'},
    ]
    const [defaultLanguage, setDefaultLanguage] = useState<string>('es');


    const [selected, setSelected] = useState<string>(lang === 'es' ? `${t("updateExperience.spanish")}` : `${t("updateExperience.english")}`);

    const [imagesShowUpload, setImagesShowUpload] = useState<boolean>(false)
    if(!token) {
        token = '';
    }

    if(idioma == null) {
        idioma='ESPAÑOL';
    }
    
    useEffect(() => { 

        if(token) {
            getClienteIDToken(token).then((res) => {
                setClientID(res);
            })   
        }  

        if(idioma.localeCompare("INGLÉS") === 0) {
            i18n.changeLanguage("en");
        } else if(idioma.localeCompare("FRANCÉS") === 0) {
            i18n.changeLanguage("fr");           
        } else if(idioma.localeCompare("ESPAÑOL") === 0) {
            i18n.changeLanguage("es");         
        }
        const storage = sessionStorage.getItem('sessionLogin');
        if(!storage) {
            history.push('/login');
        } else {
            if(!JSON.parse(storage)) {
                history.push('/login');
            } else {
                
                if(token) {
                    getExperienciaFiltrada(id,token).then((res) => {
                        if(res) {   
                            setIdOpL(res[0].operationLine_id) 
                            setName(res[0].name);
                            // Si no existe el campo array en dataweb, actualizar la experiencia para añadirle ese campo y 
                            //posteriormente hacer otra consulta
                            if(!res[0].dataweb) {
                                setDefaultLanguage('es');
                                updateExperiencia(token,"stretch", id,
                                "array",
                                {
                                    "descripcion":  {
                                        "es": "",
                                        "en": ""
                                    },
                                    "recomendaciones":  {
                                        "es": "",
                                        "en": ""
                                    }, 
                                    "localizacion":  {
                                        "es": "",
                                        "en": ""
                                    },
                                    "serviciosCercanos":  {
                                        "es": "",
                                        "en": ""
                                    },
                                    "normas":  {
                                        "es": "",
                                        "en": ""
                                    },
                                    "otros":  {
                                        "es": "",
                                        "en": ""
                                    },
                                    "terminosCondiciones": {
                                        "es": "",
                                        "en": ""                                       
                                    },
                                    "custom": {
                                        "es": "",
                                        "en": ""
                                    }
                                },'').then((t)=>{
                                    setExiste(true);
                                })                               
                            }
                            try{
                                if(res[0].dataweb !== null){

                                    if(!res[0].dataweb!.array || (typeof res[0].dataweb!.array.localizacion === 'string')){
                                        updateExperiencia(token,"stretch", id,
                                        "array",
                                        {
                                            "descripcion":  { 
                                                "es": "",
                                                "en": ""
                                            },
                                            "recomendaciones":  {
                                                "es": "",
                                                "en": ""
                                            }, 
                                            "localizacion":  {
                                                "es": "",
                                                "en": ""
                                            },
                                            "serviciosCercanos":  {
                                                "es": "",
                                                "en": ""
                                            },
                                            "normas":  {
                                                "es": "",
                                                "en": ""
                                            },
                                            "otros":  {
                                                "es": "",
                                                "en": ""
                                            },
                                            "terminosCondiciones": {
                                                "es": "",
                                                "en": ""                                       
                                            },
                                            "custom": {
                                                "es": "",
                                                "en": ""
                                            }                                      
                                        },'').then((t)=>{
                                            setExiste(true);
                                        })
                                    }
                                }
                            } catch (e) {
                                console.log('error: ', e)
                            }

                            if(res[0].dataweb !== null) {
                                setExperiencia(res[0].dataweb.array);
                                setExperienciaRecib(res[0].dataweb.array)
                                let aux_imgs = []
                                
                                for (let indice in res[0].dataweb) {
                                   if(indice.startsWith('image')) {
                                    aux_imgs.push(res[0].dataweb[indice])
                                   }
                                }
                                setImgs([...aux_imgs, ''])
                                //setImgs([...res[0].dataweb, ''])
                                setImages(res[0].dataweb);
                                setDefaultLanguage(res[0].dataweb.defaultLanguage ??  'es' )
                            }

                        }
                      });
                }          
        }
    }
    }, [existe]);

    const changeHandler = (name:any,value:any, lang: any)=>{
        if(name === 'terminosCondiciones'){
            setBloques({...bloques, [lang]: value})
        }
        value = draftToHtml(value)
        setExperiencia({...experiencia, [name]:{...experiencia[name], [lang]: value}})
    } 

    const changeImgsHandler = (name:any,value:any)=>{
        //setImages({...images, [name]: value})
        let indice_imagen = +name.slice(5)
        let imgs_divided = imgs.slice(0, imgs.length-1)
        if(indice_imagen < imgs.length) { // updated
            let aux = imgs
            aux[indice_imagen-1] = value
            setImgs(aux)
        } else { // añado nueva
            if(imgs[0] === '') {
                setImgs([value, ...imgs])
            } else {
                setImgs([...imgs_divided, value, ''])
            }
        }
    } 

    const updateExperienciaHandler = async (idioma) => {
        if(id && token) {
             // Esta petición actualiza los campos rellenados en cada pestaña de idioma
            updateExperiencia(
                token,
                "stretch",
                id,
                "array",
                {
                    "descripcion": experiencia.descripcion,
                    "recomendaciones":experiencia.recomendaciones, 
                    "localizacion": experiencia.localizacion,
                    "serviciosCercanos": experiencia.serviciosCercanos,
                    "normas": experiencia.normas,
                    "otros": experiencia.otros,
                    "terminosCondiciones": experiencia.terminosCondiciones,
                    "custom": experiencia.custom,
                }, idioma).then((res) => {

                // Esta petición actualiza el idioma por defecto
                updateExperiencia(
                    token,
                    "stretch",
                    id,
                    "defaultLanguage",
                    defaultLanguage,''
                )

                if(res === 1) {
                    Swal.fire({
                        icon: 'success',
                        title: `${t("clientsData.success")}`,
                        text: `${t("clientsData.msgSuccessUpdate")}`,
                    })   
                } else if(res.code === 456) {
                    Swal.fire({
                        icon: 'error',
                        title: `${t("main.invalidToken")}`,
                        text: `${t("main.invalidTokenmsg")}`,
                    })      
                    history.push('/login')    
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: `${t("clientsData.error")}`,
                        text: `${t("clientsData.msgErrorUpdate")}`,
                    })                           
                } 
                history.push(`/updateExperiencia/${id}`);
            });
        } 
    }

    const changeIdiomaHandler = (idioma) => {
        setDefaultLanguage(idioma)
    }
    
    let rellenoEs = experienciaRecib && experienciaRecib.terminosCondiciones && experienciaRecib.terminosCondiciones['es'] && experienciaRecib.terminosCondiciones['es'] !== '';
    let rellenoEn = experienciaRecib && experienciaRecib.terminosCondiciones && experienciaRecib.terminosCondiciones['en'] && experienciaRecib.terminosCondiciones['en'] !== '';
    if(bloques['es']){
        bloques['es'].blocks.forEach(b => {
            if(b.text !== '' && !/^\s+$/.test(b.text)) {
                rellenoEs = true;
            } else {
                rellenoEs = false;
            }
        })
    } 
    // Ahora mismo está cada comprobacion independiente, con que uno de los dos no sea vacío valdría como relleno
    if(bloques['en']) {
        bloques['en'].blocks.forEach(b => {
            if(b.text !== '' && !/^\s+$/.test(b.text)) {
                rellenoEn = true;
            } else {
                rellenoEn = false;
            }
        })       
    }

    let habilitarActualizar = false;

    // Para que la condicion sea true, 2 está relleno y 3 está vacio o 3 está lleno y 4 está lleno
    if(rellenoEs) { // Si se ha rellenado       
        if(experiencia && experiencia.custom && experiencia.custom['en'] && experiencia.custom['en'] !== '<p></p>\n'){ // está relleno customEn, entonces tiene que estar relleno termsEn
            habilitarActualizarEs = rellenoEn;
        } else {
            habilitarActualizarEs = true;
        }
    }

    if(rellenoEn) { // Si se ha rellenado      
        if(experiencia && experiencia.custom && experiencia.custom['es'] && experiencia.custom['es'] !== '<p></p>\n'){ // está relleno customEs, entonces tiene que estar relleno termsEs
            habilitarActualizarEn = rellenoEs;
        } else {
            habilitarActualizarEn = true;
        }
    }

    habilitarActualizar = habilitarActualizarEs || habilitarActualizarEn

    return(

        <MasterLayout>
            <div className="container"> 
            { experiencia && 
                <div className='col-xl-12'>
                    <div className='card mb-2 mb-xl-5'>
                        <div
                            className='card-header border-0 cursor-pointer'
                            role='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#kt_account_profile_details'
                            aria-expanded='true'
                            aria-controls='kt_account_profile_details'
                        >
                            <div className='card-title m-0' style={{alignContent:'center'}}>
                                <h1 className='fw-bolder m-0'>{t("updateExperience.experience")}: {name} </h1>
                            </div>
                        </div>
                        <div style={{margin: '20px', marginTop: '0px'}}>
                            <h2 style={{color:'#0a3154'}}>{t("updateExperience.defaultLanguage")}</h2>
                            <div className='col-lg-2 fv-row'>
                                <select
                                className='form-select form-select-solid form-select-lg' style={{fontFamily:'Barlow Condensed',backgroundColor: '#f5f5f5'}} name="pasarelaPago" onChange={(e)=>changeIdiomaHandler(e.target.value)} >
                                    {
                                        idiomas && idiomas.map(i => {
                                            return (
                                                <option value={i.value} key={i.value} selected={defaultLanguage === i.value}>{i.text}</option>
                                            )
                                        })
                                    }                 
                                </select>
                            </div>
                        </div>
            
                        <TabNav  tabs={[`${t("updateExperience.spanish")}`, `${t("updateExperience.english")}`]} selected={selected} setSelected={setSelected }>
                
                        {/* Pestaña Español */}
                            <Tab isSelected={selected === `${t("updateExperience.spanish")}` }>
                                <div id='kt_account_profile_details' className='collapse show'>     
                                    <div className='card-body border-top p-9'>
                                        <h2 style={{color:'#0a3154', marginBottom: '15px'}}>{t("updateExperience.informationWeb")}</h2>
                                        <EditorOwn nameField={'custom'} field={experiencia.custom} onChange={changeHandler} idioma={'es'}/>
                                        <h2 style={{color:'#0a3154',marginBottom: '15px', marginTop:'20px'}}><span style={{color: 'red'}}>*</span> {t("main.termsConditions")}</h2>
                                        <EditorOwn nameField={'terminosCondiciones'} field={experiencia.terminosCondiciones} onChange={changeHandler} idioma={'es'}/>
                                    </div>
                                </div>
                            </Tab>

                        {/* Pestaña Inglés */}
                            <Tab isSelected={selected ===  `${t("updateExperience.english")}` }>
                                 <div id='kt_account_profile_details' className='collapse show'>
                                    <div className='card-body border-top p-9'>
                                        <h2 style={{color:'#0a3154', marginBottom: '15px'}}>{t("updateExperience.informationWeb")}</h2>
                                        <EditorOwn nameField={'custom'} field={experiencia.custom} onChange={changeHandler} idioma={'en'}/>
                                        <h2 style={{color:'#0a3154',marginBottom: '15px', marginTop:'20px'}}><span style={{color: 'red'}}>*</span> {t("main.termsConditions")}</h2>
                                        <EditorOwn nameField={'terminosCondiciones'} field={experiencia.terminosCondiciones} onChange={changeHandler} idioma={'en'}/>
                                    </div>
                                </div>
                            </Tab>
                        </TabNav>

                        <div className='card-body border-top p-9'>
                            <div className='row mb-6'>
                                <h2 style={{color:'#0a3154'}}>{t("updateExperience.experience's images")}:</h2>
                                <div className='col-lg-8 fv-row'>                 
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'></div>
                                    </div>
                                </div>
                            </div>

                            <div className='row g-5 g-xl-8'>
                            {
                                imgs &&
                                imgs.map((im, index) => { 

                                    return(
                                        <div key={index} className='col-xl-2'>
                                            {
                                                im && im !== " " && (!loadingImage || (loadingImage && (index+1) !== indexChange)) &&
                                                <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                                                    <img src={im} onDragOver={(event) => console.log(event)} alt="img" className="img-fluid __web-inspector-hide-shortcut__"/>
                                                </div>
                                            }
                                            {
                                                (!im || im === " ") && (!loadingImage || (loadingImage && (index+1) !== indexChange)) &&
                                                <span className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative' >
                                                    <img style={{borderColor: 'darkgray', borderWidth: 'medium', borderStyle: 'dashed'}} src="https://res.cloudinary.com/marketingpyme/image/upload/w_450,h_325,c_fill/v1639043362/civitrip/plugin/sinImagen.jpg" onDragOver={(event) => console.log(event)} alt="img" className="img-fluid __web-inspector-hide-shortcut__"/>
                                                </span>
                                            }
                                            {
                                                (!im || im === " ") && loadingImage && (index+1) === indexChange && 
                                                <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative" style={{height: '160px', alignContent: 'center'}}>
                                                    <Spinner color="primary" style={{width: 100, height: 100}}></Spinner>    
                                                </div>
                                            }
                                            {
                                                im && im !== " " && loadingImage && (index+1) === indexChange && 
                                                <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative" style={{height: '160px', alignContent: 'center'}}>
                                                    <Spinner color="primary" style={{width: 100, height: 100}}></Spinner>                                          
                                                </div>
                                            }
            
                                            <br></br>
                                            <input style={{marginTop: '10px'}}
                                                type="file"
                                                id="fileupload"
                                                accept="image/*"
                                                onChange={async (event) => {           
                                                                setLoadingImage(true)   
                                                                setIndexChange(index+1)                        
                                                                const file = event.target.files[0] //aquí recibirías el archivo                                       
                                                                const data = new FormData()
                                                                data.append('imagen',file);
                                                                data.append('idCliente',clientID.toString());
                                                                data.append('nameImage',`imagen${index+1}`)
                                                                data.append('folder',`/service/${id}`)
                                                                data.append('token',token)
                                                                data.append('tipo','stretch')
                                                                data.append('id_exp',id.toString())
                                                                const res = await subirImagen(data);
                                                                let url = '';
                                                                if(res.url){
                                                                    url = res.url.replace("/upload/","/upload/w_450,h_350,c_fill/");
                                                                }
                                                                changeImgsHandler(`image${index+1}`,url);
                                                            /* let aux_imgs = imgs
                                                                aux_imgs.push("")
                                                                setImgs(aux_imgs)*/
                                                                //setLogoURL(res.secure_url)
                                                                setLoadingImage(false)    
                                                            }}                                   
                                            />
                                        </div>                           
                                    )                      
                                })
                            }              
                            </div>

                            <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                <button  onClick={(e) => {history.push(`/operationLine/${idOpL}`);}} className="btn btn-secondary" >{t("updateExperience.back")}</button>
                                {
                                    !habilitarActualizar &&
                                        <HtmlTooltip title={<React.Fragment><ul>{t("updateExperience.tooltip")}</ul></React.Fragment>} placement="top">
                                            <span>
                                                <button type='submit' className='btn btn-primary' disabled={!habilitarActualizar} onClick={() => updateExperienciaHandler('es')} >{t("clientsData.update")}</button>
                                            </span>
                                        </HtmlTooltip>             
                                }
                                {
                                    habilitarActualizar &&
                                        <button type='submit' className='btn btn-primary' disabled={!habilitarActualizar} onClick={() => updateExperienciaHandler('es')} >{t("clientsData.update")}</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
            </div>
        </MasterLayout> 
    );
}
