/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { KTSVG } from '_metronic/helpers'

type Props = {
  className: string
  data: any
  title: string
  columnas: any
  subtitulo
}

export const TableSchema: React.FC<Props> = ({className, data,title,columnas,subtitulo}) => {

  const history = useHistory();
  const [t,i18n] = useTranslation("global"); 
  let idioma; 
  useEffect(() => {
    idioma = sessionStorage.getItem('idioma');    
    if(idioma == null){
        idioma='ESPAÑOL';
    }
    if(idioma.localeCompare("INGLÉS") === 0){
        i18n.changeLanguage("en");
    }else if(idioma.localeCompare("FRANCÉS") === 0){
        i18n.changeLanguage("fr");           
    }else if(idioma.localeCompare("ESPAÑOL") === 0){
        i18n.changeLanguage("es");         
    }
  },[])
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'><h3>{title}</h3></span>
          <span className='text-muted fw-bold fs-7'>{subtitulo}</span>
        </h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          {
            data.length > 5 && 
            <button
            type='button'
            className='btn btn-sm  btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
            onClick={(e)=>{
              history.push('/viewOperationLines')
            }}      
          >
          {t(`main.seeMore${title.startsWith("S") ? "Services" : "Packages"}`)}
          </button>
          }

          {/* begin::Menu 1 */}
          {/* end::Menu 1 */}
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-5'>
            {/* begin::Table head */}
            {
              data.length > 0 && 
              <thead>
              <tr>
                {
                  columnas && columnas.map((col)=>{
                    <th className={col.class}>{col.content}</th>
                    return null
                  })
                }
                <th className='p-0 w-50px'></th>
                <th className='p-0 min-w-200px' scope="col">
                <span className='text-muted fw-bold fs-7'>{t("experiences.experience´s name")}</span></th>
                <th className='p-0 min-w-40px' scope="col"><span className='text-muted fw-bold fs-7'>{t("experiences.additional information")}</span></th>             
              </tr>
            </thead>
            }
            {
              data.length <= 0 && 
              <h3 style={{marginLeft: '40%'}}>{t("main.noAvailables")}</h3>
            }
            {/* end::Table head */}
            {/* begin::Table body */}

            <tbody>

              {

                // Para experiencia una fila
                data && data.map((d)=>{
                  let imagen = "";
                  if(d.dataweb){
                   imagen = d.dataweb.image1;
                }
                  if (imagen && imagen !== ""  && imagen !== " ") {
                      imagen = imagen.replace("/w_250,h_200,c_fill/", "/w_450,h_350/");
                  } else {
                      imagen = "https://res.cloudinary.com/marketingpyme/image/upload/w_450,h_350,c_fill/v1639043362/civitrip/plugin/sinImagen.jpg";

                  }
                  return(
                    <tr>
                    <th>
                      <div className='symbol symbol-100px me-2'>
                        <span className='symbol-label'>
                          <img
                            src={imagen}
                            className='img-fluid __web-inspector-hide-shortcut__'
                            alt=''
                          />
                        </span>
                      </div>
                    </th>
                    <td>
                      <a onClick={(e)=>{ title.startsWith("S") ? history.push(`/updateExperiencia/${d.id}`) : history.push(`/updatePackage/${d.id}`)}} className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                        {d.name}
                      </a>
                      <span className='text-muted fw-bold d-block fs-7'>{d.description}</span>
                    </td>
                    <td>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2'>
                          <span className='text-muted me-2 fs-7 fw-bold'>{d.aditionalInfo}</span>
                        </div>
                      </div>
                    </td>
                    
                    <td className='text-end'>
                      <a onClick={(e)=>{history.push(`/updateExperiencia/${d.id}`)}} className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'>
                        <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-2' />
                      </a>
                    </td>
                  </tr>
                  )
                })
              }
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
    </div>
  )
}


