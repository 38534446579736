import React, { useEffect, useState} from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import {  PasarelaPago } from '../@types/Data';
import {useTranslation} from 'react-i18next';
import { MasterLayout } from '../_metronic/layout/MasterLayout';
import Swal from 'sweetalert2'
import { useGlobalContext } from '../utils/MyGlobalContext';
import { FormItem } from './Widgets/FormItem';
import { createUser, getDataLogin } from './requests/RequestMaker';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';



interface CreateUserParams {
    username: string;
}

interface User {
    nombreUsuario,
    nombre,
    apellidos,
    email,
    rol,
    contraseña,
    shown,
    sendEmailNotifications
    
}

type CreateUserProps = RouteComponentProps<CreateUserParams>;

export const CreateUser: React.FC<CreateUserProps> = (props: CreateUserProps) => {

    const [t,i18n] = useTranslation("global");

    const items = [
        {name: 'nombreUsuario', required: true, placeholder: `${t("users.username")}`, type: 'text', readOnly: false},
        {name: 'contraseña', required: true, placeholder: `${t("users.password")}`, type: 'password', readOnly: false},   
        {name: 'email', required: true, placeholder:`${t("users.email")}`, type: 'text', readOnly: false},   
    ]

    const admin: PasarelaPago = {
        id: 1, name: 'admin'
    }
    const editor: PasarelaPago = {
        id: 2, name: 'editor'
    }  

    const { copy } = useGlobalContext();
    const history = useHistory();
    
    const initials = {
        nombreUsuario: "",
        nombre: "",
        apellidos: "",
        email: "",
        rol: "",
        contraseña: "",
        shown: false,
        sendEmailNotifications: false
    }
    const [user,setUser] = useState<User>(initials);

    let idioma : any = null;
    idioma= sessionStorage.getItem('idioma');
    if(idioma == null){
        idioma='ESPAÑOL';
    }
    let token = sessionStorage.getItem('token');
    if(!token){
        token = '';
    }
    useEffect(() => {

        i18n.changeLanguage("es");  
        if(idioma.localeCompare("INGLÉS") === 0){
            i18n.changeLanguage("en");
        }else if(idioma.localeCompare("FRANCÉS") === 0){
            i18n.changeLanguage("fr");           
        }else if(idioma.localeCompare("ESPAÑOL") === 0){
            i18n.changeLanguage("es");         
        }
        const storage = sessionStorage.getItem('sessionLogin');
        if(!storage) {
            history.push('/login');
        } else {
            if(!JSON.parse(storage)) {
                history.push('/login');
            } else {               
                const token = sessionStorage.getItem('token');
                if(token){
                    getDataLogin(token).then((res)=>{
                      if(res.code === 456){
                        Swal.fire({
                          icon: 'error',
                          title: `${t("main.invalidToken")}`,
                          text: `${t("main.invalidTokenmsg")}`,
                        })      
                        history.push('/login') 

                      }else{
                        changeHandler("rol",res[0].role)
                        if(res[0].role.localeCompare('admin') !== 0){
                            history.push('/');
                        } 
                      }
                   
                    });
                }

            }
        }
    
    }, [ history]);

      const changeHandler = (name:any,value:any)=>{
        setUser({...user, [name]: value})
    }

    const createUserHandler = () => {

        if(user) {
            if(token){
                if(user.rol === ''  || user.nombreUsuario === '' || user.nombre === '' || user.apellidos === '' || user.email === '' || user.contraseña === ''){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: t('users.missingData'),
                        footer: ''
                      })                   
                }else{
                  let sendEmailNotifications = user.rol === admin.name ? user.sendEmailNotifications : false
                    createUser(token,
                        user.nombreUsuario,
                        user.nombre,
                        user.apellidos,
                        user.email,
                        user.contraseña,
                        user.rol,
                       sendEmailNotifications
                    ).then((res)=>{
                      if(res.code === 456){
                        Swal.fire({
                          icon: 'error',
                          title: `${t("main.invalidToken")}`,
                          text: `${t("main.invalidTokenmsg")}`,
                        })      
                        history.push('/login')   
                      }else{
                        if(res.message === 'Invalid request payload input'){
                          Swal.fire({
                              icon: 'error',
                              title: 'Oops...',
                              text: t('users.missingData'),
                              footer: ''
                            })                           
                      }else{
                          if(res.client_id){
                          
                              history.push('/users');
                          }else{
                              Swal.fire({
                                  icon: 'error',
                                  title: 'Oops...',
                                  text: t("users.userExists"),
                                  footer: ''
                                })
                          }
                      }                     
                      }

                    });
                }
            }
        }
    }    
    
    return(
        <MasterLayout>

            {
                copy === 'admin' &&
                <div className='col-xl-12'>
                <div className='card mb-2 mb-xl-5'>
              <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
              >
                <div className='card-title m-0' style={{alignContent:'center'}}>
                  <h3 className='fw-bolder m-0'> <h1>{t("users.newUser")}</h1></h3>
                </div>
              </div>
        
              <div id='kt_account_profile_details' className='collapse show'>         
                <div className='card-body border-top p-9'>
                  <div className='row mb-6'>
                    <div className="col-lg-4">
                      <h2 style={{color:'#0a3154'}}>{t("users.name")}/{t("users.surname")}</h2>
                   </div>
                  <div className='col-lg-6'>
                    <div className='row'>
                      <div className='col-lg-6 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder={t("users.name")} value={user.nombre} name="nombre" style={{backgroundColor: '#f5f5f5'}} onChange={(e)=>changeHandler(e.target.name,e.target.value)}
                        
                        />                                   
                      </div>
                      <div className='col-lg-6 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          placeholder={t("users.surname")} value={user.apellidos} name="apellidos" style={{backgroundColor: '#f5f5f5'}} onChange={(e)=>changeHandler(e.target.name,e.target.value)}         
                        />                   
                      </div>
                    </div>
                  </div>
                </div>
                    {
                        items && items.map((item)=>{
                            return(
                                <FormItem  
                                key={item.name}
                                name={item.name}
                                value={user[item.name]}
                                placeholder={item.placeholder}
                                required={item.required}
                                onChange={(e)=>changeHandler(e.target.name,e.target.value)} type={item.type} readOnly={item.readOnly}
                            />
                            )
                        })
                        
                    }

               <div className='row mb-6'>
                <div className="col-lg-4">
                  <h2 style={{color:'#0a3154'}} >{t("users.role")}</h2>
                </div>
              <div className='col-lg-8 fv-row'>
                <select className='form-select form-select-solid form-select-lg' name="rol" onChange={(e)=>changeHandler(e.target.name,e.target.value)} >
                    <option value={admin.name} key={admin.id} selected={user.rol === admin.name}>Admin</option>
                    <option value={editor.name} key={editor.id} selected={user.rol === editor.name}>Editor</option>
                </select>

              </div>
                  <br></br>
                  <div className='row mb-6'>
              {
                user.rol === admin.name && 
                <FormGroup>          
                    <FormControlLabel control={<Checkbox checked={user.sendEmailNotifications} color='primary' onChange={(e)=> changeHandler('sendEmailNotifications',!user.sendEmailNotifications)} required={false} inputProps={{ 'aria-label': 'controlled' }} />}  label={`${t("users.sendNotificationsmsg")}`}   />               
                </FormGroup>   
               }
               </div>
         </div>
                </div>
                            <div className='card-footer d-flex justify-content-end py-6 px-9'>
                            <button onClick={(e)=>{
                        history.push(`/users`)
                    }} className="btn btn-secondary" >{t("updateExperience.back")}</button>
                        <button className="btn btn-primary" onClick={createUserHandler} >{t("users.createUser")}</button>
            </div>
            
            </div>

          </div>
            </div>
            }
             <div>
        </div>
        </MasterLayout>       
    );
}


