const baseURL = "https://server.crea-ticket.com";
const prefix = process.env.REACT_APP_PREFIX;

export const addUrl = async(url, token) => {
    const requestResult = await fetch(`${baseURL}${prefix}/urls`,{
        method: 'POST',
        credentials: 'include',
        headers:{
            'Content-type': 'application/json',
            'Authorization':'Bearer ' +token
        },
        body: JSON.stringify({url})
    });
    return requestResult.json();
}

export const updateUrl = async(id, url, token) => {
    const requestResult = await fetch(`${baseURL}${prefix}/urls`,{
        method: 'PUT',
        credentials: 'include',
        headers:{
            'Content-type': 'application/json',
            'Authorization':'Bearer ' +token
        },
        body: JSON.stringify({id, url})
    });
    return requestResult.json();
}

export const patchUrl = async(id, disabled, token) => {
    const requestResult = await fetch(`${baseURL}${prefix}/urls`,{
        method: 'PATCH',
        credentials: 'include',
        headers:{
            'Content-type': 'application/json',
            'Authorization':'Bearer ' +token
        },
        body: JSON.stringify({id, disabled})
    });
    return requestResult.json();
}