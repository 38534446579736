import clsx from 'clsx'
import  {FC, useEffect} from 'react'
import { toAbsoluteUrl} from '../../../helpers'

import {useLayout} from '../../core'
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',

  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'


const Topbar: FC = () => {
  const {config} = useLayout()
  const { aside} = config
  const history = useHistory()
  
  const [,i18n] = useTranslation("global");
  let idioma : any = null;
  idioma= sessionStorage.getItem('idioma');
  if(idioma === null){
    idioma='ESPAÑOL';
}


  useEffect(()=>{
    
    if(idioma.localeCompare("INGLÉS") === 0){
      i18n.changeLanguage("en");
  }else if(idioma.localeCompare("FRANCÉS") === 0){
      i18n.changeLanguage("fr");           
  }else if(idioma.localeCompare("ESPAÑOL") === 0){
      i18n.changeLanguage("es");         
  } 
  },[])
  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>


      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
          
        >
          
          <img onClick={()=>{history.push(`/profile`)}} src={toAbsoluteUrl('/media/icons/duotune/communication/com006.svg')} alt='Tus datos de cliente' />
          <br></br>
          {/*<label>{t("main.profile")}</label>*/}
        </div>
        { !aside.display && (
          <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
          style={{marginLeft: 25}}
          >
            <img onClick={()=>{history.push(`/login`)}} src={toAbsoluteUrl('/media/icons/duotune/arrows/arr077.svg')} alt='Cerrar sesión' />
            <br></br>
            {/*  <label>{t("main.logout")}</label>*/}
          </div>
        )}
        { aside.display && (
          <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
          >
            <img onClick={()=>{history.push(`/login`)}} src={toAbsoluteUrl('/media/icons/duotune/arrows/arr077.svg')} alt='Cerrar sesión' />
            <br></br>
            {/*  <label>{t("main.logout")}</label>*/}
          </div>
        )}        

        
        {/* end::Toggle */}
      </div>
      {/* end::User */} 

      {/* begin::Aside Toggler */}
      {/*config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )*/}
    </div>
  )
}

export {Topbar}
