import React, { useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import {getData} from './requests/RequestMaker'
import {useTranslation} from 'react-i18next';
import { MasterLayout } from '../_metronic/layout/MasterLayout';
import { primeraLetraMayuscula } from 'utils/Utils';
import { Spinner } from 'reactstrap';
import Swal from 'sweetalert2';


interface CollaboratorProps{
  tipo?: string
}

export const Collaborator: React.FC<CollaboratorProps> = (props: CollaboratorProps) => {

  const {tipo} = props

    const [t,i18n] = useTranslation("global");
    let idioma : any = null;
    idioma= sessionStorage.getItem('idioma');
    
    const columnas = [
        {class: "p-0 w-50px", content: ""},
        {class: "p-0 min-w-200px", content: '<span className="text-muted fw-bold fs-7">{t("experiences.experience´s name")}</span>'},
        {class: "p-0 min-w-40px", content: '<span className="text-muted fw-bold fs-7">{t("experiences.additional information")}</span>'},   
    ]

    if(idioma == null){
        idioma='ESPAÑOL';
    }

    const history = useHistory();
    let collaboratorItems;
    const [collaboratorStretches, setCollaboratorStretches] = useState<any>();
    const [collaboratorPackages, setCollaboratorPackages] = useState<any>();
    const [noHay, setNoHay] = useState<boolean>(true);

    let cargado = false;

    useEffect(() => {

        const storage = sessionStorage.getItem('sessionLogin');
        if(!storage) {
            history.push('/login');
        } else {
            if(!JSON.parse(storage)) {
                history.push('/login');
            } else {

                //useEffect
                if(sessionStorage.getItem('idioma') == null){
                    idioma='ESPAÑOL';
                }
                if(idioma.localeCompare("INGLÉS") === 0){
                    i18n.changeLanguage("en");
                }else if(idioma.localeCompare("FRANCÉS") === 0){
                    i18n.changeLanguage("fr");           
                }else if(idioma.localeCompare("ESPAÑOL") === 0){
                    i18n.changeLanguage("es");         
                }    
                const token =  sessionStorage.getItem('token');
                if(token && !collaboratorItems){  
                    getData(token).then((res: any) => {
                      if(res.code === 456){
                        Swal.fire({
                            icon: 'error',
                            title: `${t("main.invalidToken")}`,
                            text: `${t("main.invalidTokenmsg")}`,
                          })      
                          history.push('/login');         
                    }
                        switch (tipo){
                          case 'stretch': setCollaboratorStretches(res.collaboratorStretches); setNoHay(false)
                                              break;
                          case 'package': setCollaboratorPackages(res.collaboratorPackets);setNoHay(false)
                                              break;
                          default: setCollaboratorPackages(undefined);
                        }
                    });
                }
            }
        } 
    }, [tipo]);

  switch (tipo){
    case 'stretch': collaboratorItems = collaboratorStretches;
                        break;
    case 'package': collaboratorItems = collaboratorPackages;
                        break;
    default: collaboratorItems = [];
  }
  
    return (   

        <MasterLayout>
           <div className={`card card card-xl-stretch mb-xl-8`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>       
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'><h3>{t("main.collaboratorServices")}</h3></span>
          <span className='text-muted fw-bold fs-7'></span>
        </h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          {/* begin::Menu 1 */}
          {/* end::Menu 1 */}
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-5'>
            {/* begin::Table head */}
            <thead>
              <tr>
                {

                  columnas && columnas.map((col)=>{
                    <th className={col.class}>{col.content}</th>
                    return null
                  })
                }
                <th className='p-0 w-50px'></th>
                <th className='p-0 min-w-200px' scope="col">
                <span className='text-muted fw-bold fs-7'>{t("experiences.experience´s name")}</span></th>
                <th className='p-0 min-w-40px' scope="col"><span className='text-muted fw-bold fs-7'>{t("experiences.additional information")}</span></th>             
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}

            <tbody>

              {/* CollaboratorStretches*/}

              {
                // Para experiencia una fila
                collaboratorItems && collaboratorItems.map((d)=>{
                  let imagen = "";
                  if(d.dataweb){
                    imagen = d.dataweb.image1;
                  }
                  if (imagen && imagen !== "") {
                      imagen = imagen.replace("/w_250,h_200,c_fill/", "/w_110,h_75/");
                  } else {
                      imagen = "https://res.cloudinary.com/marketingpyme/image/upload/c_scale,h_75,w_110/v1639043362/civitrip/plugin/sinImagen.jpg";

                  }
                  return(
                    <tr>
                    <th>
                      <div className='symbol symbol-50px me-2'>
                        <span className='symbol-label'>
                          <img
                            src={imagen}
                            className='img-fluid __web-inspector-hide-shortcut__'
                            alt=''
                          />
                        </span>
                      </div>
                    </th>
                    <td>
                      <a onClick={(e)=>{ history.push(`/collaborator${primeraLetraMayuscula(tipo)}/${d.operator_id}/${d.id}`)}} className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                        {d.name}
                      </a>
                      <span className='text-muted fw-bold d-block fs-7'>{d.description}</span>
                    </td>
                    <td>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2'>
                          <span className='text-muted me-2 fs-7 fw-bold'>{d.aditionalInfo}</span>
                        </div>
                      </div>
                    </td>
                    <td className='text-end'>
                        <button onClick={(e)=>{
                            history.push(`/collaborator${primeraLetraMayuscula(tipo)}/${d.operator_id}/${d.id}`);
                        }} className="btn btn-primary btn-sm">{t("experiences.seeMoreInformation")}</button>
                    </td>
                  </tr>
                  )
                })
              }
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
        {
               noHay && (!collaboratorItems) &&                                  
            <div style={{top:'50%',left:'50%,',display: 'flex',justifyContent: 'center', alignItems: 'center'}}>             
             <Spinner color="primary" style={{width: 100, height: 100}}></Spinner>
            </div>                                       
        } 
         {
               !noHay && (collaboratorItems && collaboratorItems.length === 0) &&                                  
            <div style={{top:'50%',left:'50%,',display: 'flex',justifyContent: 'center', alignItems: 'center'}}>             
             {tipo === 'stretch' ? t("experiences.noServices") : t("experiences.noPackages")}
            </div>                                       
        }        
      </div>
    </div>     
        </MasterLayout>
       
    );
}
