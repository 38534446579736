import React, {useCallback, useEffect, useState} from 'react';
import { RouteComponentProps,useHistory } from 'react-router-dom';
import { OperationLine} from '../@types/Data';
import {getData} from './requests/RequestMaker'
import {useTranslation} from 'react-i18next';
import { MasterLayout } from '../_metronic/layout/MasterLayout';
import { OperationCard } from './OperationCard';
import { Spinner } from 'reactstrap';
import Swal from 'sweetalert2';

interface ViewExperienciasParams {
    id: string;
}

type ViewExperienciasProps = RouteComponentProps<ViewExperienciasParams>;

export const ViewOperationLines: React.FC<ViewExperienciasProps> = (props: ViewExperienciasProps) => {
    
    const [operationLines, setOperationLines] = useState<OperationLine[]>();
    const [t,i18n] = useTranslation("global");
    let idioma : any = null;
    idioma= sessionStorage.getItem('idioma');
    const [noHay, setNoHay] = useState<boolean>(false);

    if(idioma == null){
        idioma='ESPAÑOL';
    }

    const history = useHistory();

    useEffect(() => {
        const storage = sessionStorage.getItem('sessionLogin');
        const token =  sessionStorage.getItem('token');
        if(!storage) {
            history.push('/login');
        } else {
            if(!JSON.parse(storage)) {
                history.push('/login');
            } else {

                //useEffect
                if(sessionStorage.getItem('idioma') == null) {
                    idioma='ESPAÑOL';
                }
                if(idioma.localeCompare("INGLÉS") === 0) {
                    i18n.changeLanguage("en");
                } else if(idioma.localeCompare("FRANCÉS") === 0) {
                    i18n.changeLanguage("fr");           
                } else if(idioma.localeCompare("ESPAÑOL") === 0) {
                    i18n.changeLanguage("es");         
                }       
                if (token != null) {
                    getData(token).then((res: any) => {
                        if(res.code === 456) {
                            Swal.fire({
                                icon: 'error',
                                title: `${t("main.invalidToken")}`,
                                text: `${t("main.invalidTokenmsg")}`,
                              })      
                              history.push('/login');         
                        }
                        if(!res.operationLines) {
                            setNoHay(true);
                        } else {
                            let aux_noHay = true
                            res.operationLines.forEach(operationLine => {
                                if(operationLine.stretches.length === 0 && aux_noHay) {
                                    aux_noHay = true
                                } else if(operationLine.stretches.length > 0) {
                                    aux_noHay = false
                                }
                            });
                            setNoHay(aux_noHay)
                        }
                        setOperationLines(res.operationLines);
                    });
                  }
            }
        } 
    }, [history]);

    const operationLineHandler = useCallback((o) => {
        history.push(`/operationLine/${o.id}`)
    }, []);

    return (   
        <MasterLayout>
            <div className="container">  
                <div className="row">
                    <h1>{t("experiences.selectLine")}</h1>
                    <br></br>
                    <br></br>
                    <br></br>
                    {   
                        operationLines &&  operationLines.map((operationLine) => { 
                            /*if(operationLine.stretches.length === 0 && noHay){
                                noHay = true;
                            }else if(operationLine.stretches.length !== 0){
                                noHay = false;
                            }*/
                            let imagen = "https://res.cloudinary.com/marketingpyme/image/upload/w_900,h_700,c_fill/v1639043362/civitrip/plugin/sinImagen.jpg";                                                         
                            return (
                                <React.Fragment key={operationLine.id}>
                                    {
                                        operationLine.stretches.length !==0 && 
                                            <div className="col-sm-2" onClick={()=>operationLineHandler(operationLine)}>
                                                <OperationCard opL={operationLine} imagen={imagen} key={operationLine.id} id_operationLine={operationLine.id}  isPacket={true} ></OperationCard>
                                            </div>
                                    }
                                </React.Fragment>
                            );
                        })
                    }

                    {
                        !noHay && !operationLines &&                                      
                        <div style={{top:'50%',left:'50%,',display: 'flex',justifyContent: 'center', alignItems: 'center'}}>             
                        <Spinner color="primary" style={{width: 100, height: 100}}></Spinner>
                        </div>                                       
                    }

                    {
                        noHay && operationLines &&                           
                        <div>             
                            <h1 style={{marginLeft: '55%'}}>{t("experiences.noServices")}</h1>
                        </div>                                         
                    }
                </div>
            </div>
        </MasterLayout>      
    );
}



