import React from 'react';
import { OperationLine } from '../@types/Data';


interface CardStretchProps {
    opL: OperationLine,
    imagen: string,
    id_operationLine: number,
    isPacket: boolean
}

export const OperationCard: React.FC<CardStretchProps> = ({opL, imagen, id_operationLine,isPacket}) => {
 
    return(
        <>

            <div className="destinations-three__single_custom" style={{ margin: 8, borderRadius: 10, backgroundColor: '#f5f8fa', justifyContent: 'center', alignItems: 'center',display: 'flex' }}>
                    <div style={{textAlign:'center'}}>
                        <h3 ><a style={{textAlign:'center', fontWeight: 500}}>{opL.name}</a></h3>
                    </div>

            </div>
            </>

        

    );
}
