const baseURL = "https://server.crea-ticket.com";
const prefix = process.env.REACT_APP_PREFIX;
//const baseURL = "https://api.civitrip.com";
// const baseURL = 'https://api.marketingpyme.com';
// const baseURL = "http://localhost:4000";
// Request Base
export const baseRequest = async<T>(route: string, config?: RequestInit): Promise<T> => {
    config = { credentials: 'include', ...(config || {}) };
    const requestResult = await fetch(`${baseURL}${prefix}/${route}`, config);
    return requestResult.json();
};

export const getLoginClientes = async (client: string, user: string, password: string) => {
    const request = await fetch(`${baseURL}${prefix}/accesoCliente/login`, {
        headers: { 'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify({client, user, password}),
        method: 'POST'
    });
    return request.json();

};

export const getUsers = async(token:string)=>{
    const requestResult = await fetch(`${baseURL}${prefix}/accesoCliente/getUsers`,{
        method: 'GET',
        credentials: 'include',
        headers:{
            'Content-type': 'application/json',
            'Authorization':'Bearer ' +token
        }
    });
    return requestResult.json();
}

export const getUser = async(token:string)=>{
    const requestResult = await fetch(`${baseURL}${prefix}/accesoCliente/getUser`,{
        method: 'GET',
        credentials: 'include',
        headers:{
            'Content-type': 'application/json',
            'Authorization':'Bearer ' +token
        }
    });
    return requestResult.json();
}

export const updateUser = async(token: string, idUser: number,nombreUsuario: string,nombre:string,apellidos:string,contraseña: string,email:string,rol:string, sendEmailNotifications?:boolean)=>{
    const request = await fetch(`${baseURL}${prefix}/accesoCliente/updateUser`, {
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+token},
        credentials: 'include',
        body: JSON.stringify({
            idUser,
            nombreUsuario,
            nombre,
            apellidos,
            contraseña,
            email,
            rol,
            sendEmailNotifications
        }),
        method: 'POST'
    });
    return request.json();
}

export const createUser = async(token: string, nombreUsuario: string,nombre:string,apellidos:string,email:string,contraseña:string,rol:string, sendEmailNotifications:boolean)=>{
    const request = await fetch(`${baseURL}${prefix}/accesoCliente/createUser`, {
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+token},
        credentials: 'include',
        body: JSON.stringify({          
            nombreUsuario,
            nombre,
            apellidos,
            email,
            contraseña,
            rol,
            sendEmailNotifications
        }),
        method: 'POST'
    });   
    return request.json();
}

export const sendEmail = async (cliente: string, user: string) =>{
    const request = await fetch(`${baseURL}${prefix}/accesoCliente/forgottenPassword`, {
        headers: { 'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify({
            cliente,
            user
        }), 
        method: 'POST'
    });
    
    return request.json();
}

export const getClienteIDToken = async(token: string) => {  
    const request =  await fetch(`${baseURL}${prefix}/getClientIDToken`, {
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+token}});
    return request.json();
}

export const updateExperiencia = async(token: string,tipo:string,id_exp:number,key:string,valor:any, idioma: string) => {
    const request = await fetch(`${baseURL}${prefix}/accesoCliente/updateExperiencia`, {
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+token},
        credentials: 'include',
        body: JSON.stringify({
            tipo,
            id_exp,
            key,
            valor,
            idioma
        }),
        method: 'POST'
    });
    return request.json();
    
  }

  export const getLogo = async (idCliente: string) =>{
  const request = await fetch(`${baseURL}${prefix}/accesoCliente/getLogo`, {
    headers: { 'Content-Type': 'application/json'},
      credentials: 'include',
      body: JSON.stringify({idCliente}),
      method: 'POST'
  });
 // const res = request.text()
 // console.log(res)
  return request.json(); 
}

  export const subirImagen = async (data:FormData) =>{
    const request = await fetch(`${baseURL}${prefix}/accesoCliente/subirImagen`, {
        credentials: 'include',
        body: data,
        method: 'POST'
    });
    return request.json(); 
 }

  export const borrar_imagen = async (public_id:string) =>{
    const request = await fetch(`${baseURL}${prefix}/accesoCliente/borrarImagen`, {
        headers: { 'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify({public_id}),
        method: 'POST'
    });
    return request.json(); 
 }

 export const recoveryPassword = async (token:string, password:string) => {
    const request = await fetch(`${baseURL}${prefix}/accesoCliente/recovery`, {
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+token},
        credentials: 'include',
        body: JSON.stringify({password}),
        method: 'POST'
    });
    return request.json();
}

export const getClienteID = async (client: string, user: string, password: string) =>{
    
    const request = await fetch(`${baseURL}${prefix}/accesoCliente/clienteID`, {
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({client,user, password}),
        method: 'POST'
    });
    return request.text();         
} 

export const getDataLogin = async(token:string)=>{
    const requestResult = await fetch(`${baseURL}${prefix}/accesoCliente/getDataLogin`,{
        method: 'GET', // or 'PUT'
        credentials: 'include',
        headers:{
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+token
        }
      });
        return requestResult.json();  
}

export const availableUser = async(token: string, idUser: number,disabled_at)=>{
    const request = await fetch(`${baseURL}${prefix}/accesoCliente/availableUser`, {
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+token},
        credentials: 'include',
        body: JSON.stringify({
            idUser,
            disabled_at
        }),
        method: 'POST'
    });
    return request.json();
}

export const updateClientToken = async(token: string, idTicando: number, Dominio: string, idiomaNotificacion: string, pasarela_options:any) => {
             
        const request = await fetch(`${baseURL}${prefix}/accesoCliente/updateClientToken`, {
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+token},
        credentials: 'include',
        body: JSON.stringify({
            idTicando,
            Dominio,
            idiomaNotificacion,
            pasarela_options
        }),
        method: 'POST'
    });
    return request.json();
}

export const updateClientTerms = async(token: string, terms: any) => {
             
        const request = await fetch(`${baseURL}${prefix}/accesoCliente/updateClientTerms`, {
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+token},
        credentials: 'include',
        body: JSON.stringify({
            terms
        }),
        method: 'POST'
    });
    return request.json();
}

export const deleteUser = async(id: number) => {
    return await baseRequest<number>(` /deleteUser?id=${id}`);
}

export const getPackageFiltrada = async(id_p:number,token:string) =>{
    const alldata = await getData(token);
    const opL =alldata.operationLines; 
      const stretch:any = [];
      opL.forEach(function(linea:any)  {
          linea.packets.forEach(function(s:any){
              stretch.push(s);
          }) 
  
      });
      return stretch.filter(function(e:any) { return e.id===id_p});
}

export const getData = async (token:string) => { 
    const requestResult = await fetch(`${baseURL}${prefix}/data`,{
    method: 'GET', // or 'PUT'
    credentials: 'include',
    headers:{
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+token
    }
  });
    return requestResult.json();
};
 
export const getExperienciaFiltrada = async(id_exp:number,token:string) =>{
    const alldata = await getData(token);
    const opL =alldata.operationLines; 
      const stretch:any = [];
      opL.forEach(function(linea:any)  {
          linea.stretches.forEach(function(s:any){
              stretch.push(s);
          }) 
      });
      return stretch.filter(function(e:any) { return e.id===id_exp});
  }

  export const getClientByIDPC = async(token: string) => {
    const requestResult = await fetch(`${baseURL}${prefix}/panelControl/getClientByIDPC`,{
        method: 'GET', // or 'PUT'
        credentials: 'include',
        headers:{
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+token
        }
      });
        return requestResult.json();
}

export const getBookings = async(privateApiKey: string) => {
    const requestResult = await fetch(`${baseURL}${prefix}/tmt/getBookings`,{
        method: 'GET', // or 'PUT'
        credentials: 'include',
        headers:{
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+privateApiKey
        }
      });
        return requestResult.json();
}

export const getPasarelas= async()=>{
    const requestResult = await fetch(`${baseURL}${prefix}/getPasarelas`,{
        method: 'GET',
        credentials: 'include',
        headers:{
            'Content-type': 'application/json',
        }
    });
    return requestResult.json();
}

export const getPasarelaOptions = async(name:string, client_id, token)=>{
    const requestResult = await fetch(`${baseURL}${prefix}/getPasarelaOptions?pasarela_name=${name}&client_id=${client_id}&panelCliente=${true}`,{
        method: 'GET',
        credentials: 'include',
        headers:{
            'Content-type': 'application/json',
            'Authorization':'Bearer ' +token
        }
    });
    return requestResult.json();
}

export const getPagos = async(token)=>{
    const requestResult = await fetch(`${baseURL}${prefix}/getPagos`,{
        method: 'GET',
        credentials: 'include',
        headers:{
            'Content-type': 'application/json',
            'Authorization':'Bearer ' +token
        }
    });
    return requestResult.json();
}

export const getReservasByClient = async(token) => {
    const requestResult = await fetch(`${baseURL}${prefix}/getReservasByClient`,{
        method: 'POST',
        credentials: 'include',
        headers:{
            'Content-type': 'application/json',
            'Authorization':'Bearer ' +token
        }
    });
    return requestResult.json();  
}