import React, { useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MasterLayout } from '../_metronic/layout/MasterLayout';
import Swal from 'sweetalert2'
import { FormItem } from './Widgets/FormItem';
import { getUser, updateUser } from './requests/RequestMaker';
import { Spinner } from 'reactstrap';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import { PasarelaPago } from '../@types/Data';





export const Profile: React.FC<any> = () => {
 
    const [t,i18n] = useTranslation("global");

    const items = [
        {name: 'username', required: false, placeholder: `${t("users.username")}`, type: 'text', readOnly: true},
        {name: 'password', required: true, placeholder: `${t("users.password")}`, type: 'password', readOnly: false},   
        {name: 'email', required: true, placeholder:`${t("users.email")}`, type: 'text', readOnly: false},  
        {name: 'role', required: false, placeholder: `${t("users.role")}`, type: 'text', readOnly: true}, 
    ]

    const admin: PasarelaPago = {
      id: 1, name: 'admin'
  }
  
    const initials = {
        username: "",
        name: "",
        surname : "",
        email: "",
        role: "",
        password: "",
        sendEmailNotifications: false
    }

    const history = useHistory();
    //const id = +props.match.params.username; 
    const [user,setUser] = useState<any>(initials);

    let idioma : any = null;
    idioma= sessionStorage.getItem('idioma');
    if(idioma == null){
        idioma='ESPAÑOL';
    }
    let token = sessionStorage.getItem('token');
    if(!token){
        token = '';
    }
    
    useEffect(() => {
 
        i18n.changeLanguage("es");  
        if(idioma.localeCompare("INGLÉS") === 0){
            i18n.changeLanguage("en");
        }else if(idioma.localeCompare("FRANCÉS") === 0){
            i18n.changeLanguage("fr");           
        }else if(idioma.localeCompare("ESPAÑOL") === 0){
            i18n.changeLanguage("es");         
        }
        const storage = sessionStorage.getItem('sessionLogin');
        if(!storage) {
            history.push('/login');
        } else {
            if(!JSON.parse(storage)) {
                history.push('/login');
            } else {               
                const token = sessionStorage.getItem('token');
                if(token){   
                    
                    getUser(token).then((res)=>{
                      if(res.code === 456){
                        Swal.fire({
                          icon: 'error',
                          title: `${t("main.invalidToken")}`,
                          text: `${t("main.invalidTokenmsg")}`,
                        })      
                        history.push('/login')  
                      }else{
                        setUser(res[0]);
                      }
                    })                          
                }               
            }
        }
    }, [ history]);

    const changeHandler = (name:any,value:any)=>{
        setUser({...user, [name]: value})
    }

    const updateUserHandler = () => {
        if(user) {
            if(token){
                updateUser(token,
                    user.id,
                    user.username,
                    user.name,
                    user.surname,
                    user.password,
                    user.email,
                    user.role,
                    user.sendEmailNotifications
                ).then((res)=>{
                  if(res === 1){
                    Swal.fire({
                      icon: 'success',
                      title: `${t("clientsData.success")}`,
                      text: `${t("clientsData.msgSuccessUser")}`,
                    }) 
                  } else if(res.code === 456){
                    Swal.fire({
                      icon: 'error',
                      title: `${t("main.invalidToken")}`,
                      text: `${t("main.invalidTokenmsg")}`,
                    })      
                    history.push('/login')  
                  }else{
                    Swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: t('users.missingData'),
                      footer: ''
                    })         
                  }
                    /*if(res.message === 'Invalid request payload input'){
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: t('users.missingData'),
                            footer: ''
                          })                           
                        }else{
                            Swal.fire({
                                icon: 'success',
                                title: 'Actualizado con éxito',
                                text: 'Sus datos de cliente se han actualizado correctamente',
                              })  
                        }*/
                   
                });
            }
        }
    }  
     
    return(

        <MasterLayout >
            {
                user.username && 
                <div className='col-xl-12'>
                <div className='card mb-2 mb-xl-5'>
              <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
              >
                <div className='card-title m-0'>
                  <h1>{t("users.user")} {user.username}</h1>
                </div>
              </div>
        
              <div id='kt_account_profile_details' className='collapse show'>
            
                  <div className='card-body border-top p-9'>
                  <div className='row mb-6'>
                      { user.surname !== "" &&
                        <div className='col-lg-4'>
                          <h2 style={{color:'#0a3154'}}>{t("users.name")}/{t("users.surname")}</h2>
                        </div>
                      }
                      { user.surname === "" &&
                        <div className='col-lg-4'>
                          <h2 style={{color:'#0a3154'}}>{t("users.name")}</h2>
                        </div>
                      }                      

              <div className='col-lg-6'>
                <div className='row'>
                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder={t("users.name")} value={user.name} name="name" style={{backgroundColor: '#f5f5f5'}} onChange={(e)=>changeHandler(e.target.name,e.target.value)}
                     
                    />
                   
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'></div>
                      </div>
                    
                  </div>
                  {
                          user.surname !== "" &&
                  <div className='col-lg-6 fv-row'>

                          <input
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          placeholder={t("users.surname")} value={user.surname} name="surname" style={{backgroundColor: '#f5f5f5'}}  onChange={(e)=>changeHandler(e.target.name,e.target.value)}        
                        />
                      

                    
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'></div>
                      </div>                    
                  </div>}
                </div>
              </div>
            </div>
                    {
                        items && items.map((item)=>{
                            return(
                                <FormItem  
                                name={item.name}
                                value={user[item.name]}
                                placeholder={item.placeholder}
                                required={item.required}
                                onChange={(e)=>changeHandler(e.target.name,e.target.value)} type={item.type} readOnly={item.readOnly}
                            />
                            )
                        })
                        
                    }
              <div className='row mb-6'>
              {
                user.role === admin.name && 
                <FormGroup>          
                    <FormControlLabel control={<Checkbox checked={user.sendEmailNotifications || user.name === 'Administrador'} color='primary' onChange={(e)=> {if(user.name === 'Administrador'){changeHandler('sendEmailNotifications',true)}else{changeHandler('sendEmailNotifications',!user.sendEmailNotifications)}}} required={false} inputProps={{ 'aria-label': 'controlled' }} />}  label={`${t("users.receiveNotificationsmsg")}`}   />               
                </FormGroup>   
               }
               </div>
                </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' onClick={updateUserHandler}>{t("clientsData.update")}</button>
            </div>
            
            </div>

          </div>
            </div>

            }
        {
               !user.username &&                                      
            <div style={{top:'50%',left:'50%,',display: 'flex',justifyContent: 'center', alignItems: 'center'}}>             
             <Spinner color="primary" style={{width: 100, height: 100}}></Spinner>
            </div>                                       
        }
        </MasterLayout>       
    );
}


