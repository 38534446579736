import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { MainPage as ACMainPage } from './components/MainPage';
import {LoginClients} from './components/Login'
import {ViewOperationLines} from './components/ViewOperationLines'
import {OpLine} from './components/OpLine'
import {ViewOperationLinesPackages} from './components/ViewOperationLinesPackages'
import {OpLinePackages} from './components/OpLinePackages'
import {UpdateExperiencia} from './components/UpdateExperiencia'
import {UpdatePackage} from './components/UpdatePackage'
import {Idioma} from './components/Idioma'
import {Users} from './components/Users'
import {UpdateUser} from './components/UpdateUser'
import {CreateUser} from './components/CreateUser'
import {DataClient} from './components/DataClient'
import { RecoveryPassword } from 'components/RecoveryPassword';
import { Profile } from 'components/Profile';
import { ForgottenPassword } from 'components/ForgottenPassword';
import { MasterInit } from '_metronic/layout/MasterInit';
import { Collaborator } from 'components/Collaborator';
import { InfoCollaborator } from 'components/InfoCollaborator';
import { Sales } from 'components/Sales';
import { TermsConditions } from 'components/TermsConditions';
import { Pagos } from 'components/Pagos';

export const Routes: React.FC = () => {
    return (
        <BrowserRouter>
            <Switch>

                <Route exact path='/:token/recoveryPassword' component={RecoveryPassword}/> 

                {/*Panel Clientes*/}
                <Route exact path='/' component={ACMainPage} />
                <Route exact path='/dataClient' component={DataClient} />
                <Route exact path='/login' component={LoginClients}/>
                <Route exact path='/viewOperationLines' component={ViewOperationLines}/>
                <Route exact path='/operationLine/:idop' component={OpLine}/>
                <Route exact path='/viewOperationLinesPackages' component={ViewOperationLinesPackages}/>
                <Route exact path='/operationLinePackage/:idop' component={OpLinePackages}/>
                <Route exact path='/updateExperiencia/:id' component={UpdateExperiencia}/>
                <Route exact path='/updatePackage/:id' component={UpdatePackage}/>  
                <Route exact path='/viewCollaboratorStretches' render={props => <Collaborator tipo={'stretch'}/>}/>
                <Route exact path='/viewCollaboratorPackages' render={props => <Collaborator tipo={'package'}/>}/> 
                <Route exact path='/collaboratorStretch/:operator_id/:id' render={props => <InfoCollaborator {...props} tipo={'stretch'}/>}/> 
                <Route exact path='/collaboratorPackage/:operator_id/:id' render={props => <InfoCollaborator {...props} tipo={'package'}/>}/>                    
                <Route exact path='/idioma' component={Idioma}/>   
                <Route exact path='/sales' component={Sales}/>  
               {/*<Route exact path='/pagos' component={Pagos}/>*/} 
                <Route exact path='/termsConditions' component={TermsConditions}/>  
                {/*<Route exact path='/shortcodes' component={ShortCodes}/>  */}
                <Route exact path='/users' component={Users}/>    
                <Route exact path='/updateUser/:username' component={UpdateUser}/>   
                <Route exact path='/createUser' component={CreateUser}/>   
                <Route exact path='/profile' component={Profile}/> 
                <Route exact path='/forgottenPassword' component={ForgottenPassword}/>       
            </Switch>
            <MasterInit />
        </BrowserRouter>
    );
};
