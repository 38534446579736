/* eslint-disable react/jsx-no-target-blank */
import { getDataLogin } from 'components/requests/RequestMaker';
import  { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from '../../../../utils/MyGlobalContext';
import {AsideMenuItem} from './AsideMenuItem'



export function AsideMenuMain(props:any) {
  const [t,i18n] = useTranslation("global");
  let idioma : any = null;
  idioma= sessionStorage.getItem('idioma');
  if(idioma === null){
    idioma='ESPAÑOL';
}
const token =  sessionStorage.getItem('token');
const { copy,setCopy } = useGlobalContext();

  useEffect(()=>{
    
    if(idioma.localeCompare("INGLÉS") === 0){
      i18n.changeLanguage("en");
  }else if(idioma.localeCompare("FRANCÉS") === 0){
      i18n.changeLanguage("fr");           
  }else if(idioma.localeCompare("ESPAÑOL") === 0){
      i18n.changeLanguage("es");         
  }   

  if(!copy){
    if(token){
      getDataLogin(token).then((res)=>{
        setCopy(res[0].role);                 
      }); 
    } 
  }



if(!copy){
  if(sessionStorage.getItem('cjluc') != null){
    if(sessionStorage.getItem('cjluc') === '0'){
      setCopy('admin');
    }else{
      setCopy('editor');
    }
   // setCopy(sessionStorage.getItem('rol') || '{}');
  } 
}
  },[])

  if(copy === "admin"){
    return (
      <>
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Dashboard</span>
          </div>
        </div>  
        <AsideMenuItem 
          to={`/`}
          icon='/media/icons/duotune/general/gen001.svg'
          title={t("main.home")} 
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to={`/viewOperationLines`}
          icon='/media/icons/duotune/maps/map009.svg'
          title={t("main.services")}
          fontIcon='bi-layers'
        /> 
        <AsideMenuItem
          to={`/viewOperationLinesPackages`}
          title={t("main.packages")}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen017.svg'
        />
          <AsideMenuItem
          to={`/viewCollaboratorStretches`}
          title={t("main.collaboratorServices")}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen025.svg'
        />
          <AsideMenuItem
          to={`/viewCollaboratorPackages`}
          title={t("main.collaboratorPackages")}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen028.svg'
        />
       {/* <AsideMenuItem
          to={`/shortcodes`}
          title={t("main.shortcodes")}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/coding/cod003.svg'
        />  */}
          <AsideMenuItem
          to={`/dataClient`}
          title={t("main.customerData")}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/communication/com006.svg'
        />
        <AsideMenuItem
          to={`/sales`}
          title={t("main.sales")}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/ecommerce/ecm008.svg'
        />
        {/*<AsideMenuItem
          to={`/pagos`}
          title={t("main.payments")}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/finance/fin008.svg'
        /> */}
        <AsideMenuItem
          to={`/users`}
          title={t("main.users")}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/communication/com005.svg'
        />   
        <AsideMenuItem
          to={`/termsConditions`}
          title={t("main.termsConditions")}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen005.svg'
        />      
        <AsideMenuItem
          to={`/idioma`}
          title={t("main.languages")}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/communication/com001.svg'
        /> 
        <AsideMenuItem
          to='/login'
          title={t("main.logout")}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/arrows/arr077.svg'
        />          
      </>
    )
  }else{
    return (
      <>
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Dashboard</span>
          </div>
        </div>  
        <AsideMenuItem 
          to={`/`}
          icon='/media/icons/duotune/general/gen001.svg'
          title={t("main.home")} 
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to={`/viewOperationLines`}
          icon='/media/icons/duotune/maps/map009.svg'
          title={t("main.services")}
          fontIcon='bi-layers'
        />  
        <AsideMenuItem
          to={`/viewOperationLinesPackages`}
          title={t("main.packages")}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen017.svg'
        />
        <AsideMenuItem
          to={`/idioma`}
          title={t("main.languages")}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/communication/com001.svg'
        /> 
        <AsideMenuItem
          to='/login'
          title={t("main.logout")}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/arrows/arr077.svg'
        />        
  
      </>
    )
  
  }
 
}
