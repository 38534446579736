import React, { useEffect, useState, forwardRef} from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { PanelControl } from '../@types/Data';
import { getDataLogin, availableUser, getUsers } from './requests/RequestMaker'
import {useTranslation} from 'react-i18next';
import { MasterLayout } from '../_metronic/layout/MasterLayout';
import { useGlobalContext } from '../utils/MyGlobalContext';
import Swal from 'sweetalert2'
import MaterialTable, { Icons } from 'material-table';
import { AddBox, Check, Clear, DeleteOutline, ChevronRight, Edit, SaveAlt, FilterList, FirstPage, LastPage, Search, ChevronLeft, ArrowDownward, Remove, ViewColumn  } from '@material-ui/icons';
import ToggleOffOutlinedIcon from '@material-ui/icons/ToggleOffOutlined';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import { Spinner } from 'reactstrap';

interface UsersParams {
    id: string;
}

type UsersProps = RouteComponentProps<UsersParams>;

export const Users: React.FC<UsersProps> = (props: UsersProps) => {
    const [t,i18n] = useTranslation("global");
    const history = useHistory();
    const id = props.match.params.id; 
    const [client, ] = useState<PanelControl>();
    const [USERPCCIVITRIP, setUSERPCCIVITRIP] = useState<string>("");
    const [, setPASSWORDPCCIVITRIP] = useState<string>("");
    const [, setRole] = useState<string>("");
    const [users,setUsers] = useState<any>([]);
    const [borrado,setBorrado] = useState<boolean>(false);
    const [noHay,setNoHay] = useState<boolean>(false);

    const { copy } = useGlobalContext();
    let idioma : any = null;
    idioma= sessionStorage.getItem('idioma');
    if(idioma == null){
        idioma='ESPAÑOL';
    }
    let token = sessionStorage.getItem('token');
    if(!token){
        token = '';
    }

    const tableIcons: Icons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
      };
      
      const columns = [
        {title:`${t("users.username")}`, field: "username",filterPlaceholder:`${t("users.filter")}`},
        {title:`${t("users.name")}`, field: "name",filterPlaceholder:`${t("users.filter")}`},
        {title:`${t("users.surname")}`, field: "surname",filterPlaceholder:`${t("users.filter")}`}, 
        {title:`${t("users.email")}`, field: "email",filterPlaceholder:`${t("users.filter")}`},       
        {title:`${t("users.role")}`, field: "role",filterPlaceholder:`${t("users.filter")}`},    
        {title: `${t("users.disabled")}`, field: "disabled_at", filterPlaceholder:`${t("users.filter")}`, render:(rowData)=><div style={{textAlign:'center'}}>{rowData.disabled_at ? rowData.disabled_at.substr(0,rowData.disabled_at.indexOf("T")) : "-" }</div>},
        {title: `${t("users.salesNotifications")}`, field: "sendEmailNotifications", filterPlaceholder:`${t("users.filter")}`, render:(rowData)=><div style={{textAlign:'center'}}>{(rowData.sendEmailNotifications === 1 || rowData.name === 'Administrador') ? `${t("users.yes")}` : `${t("users.no")}` }</div>}
    ]

    useEffect(() => {

        i18n.changeLanguage("es");  
        if(idioma.localeCompare("INGLÉS") === 0){
            i18n.changeLanguage("en");
        }else if(idioma.localeCompare("FRANCÉS") === 0){
            i18n.changeLanguage("fr");           
        }else if(idioma.localeCompare("ESPAÑOL") === 0){
            i18n.changeLanguage("es");         
        }

        const storage = sessionStorage.getItem('sessionLogin');
        if(!storage) {
            history.push('/login');
        } else {
            if(!JSON.parse(storage)) {
                history.push('/login');
            } else {
                if(!client) {
                    const token = sessionStorage.getItem('token');
                    if(token){  
                        getDataLogin(token).then((res) => {
                            if(res.code === 456) {
                                Swal.fire({
                                    icon: 'error',
                                    title: `${t("main.invalidToken")}`,
                                    text: `${t("main.invalidTokenmsg")}`,
                                  })      
                                 history.push('/login')          
                            } else {
                                setUSERPCCIVITRIP(res[0].username);
                                setPASSWORDPCCIVITRIP(res[0].password);
                                setRole(res[0].role);
                                if(res[0].role.localeCompare('admin') !== 0) {
                                    history.push('/');
                                }
                            
                                const userLogued = res[0]
                                const u:any = [];
                                getUsers(token).then((res) => {
                                    if(res.code === 456) {
                                        Swal.fire({
                                            icon: 'error',
                                            title: `${t("main.invalidToken")}`,
                                            text: `${t("main.invalidTokenmsg")}`,
                                        })      
                                        history.push('/login')     
                                    } else {
                                        let isLoginAdministrator = userLogued.username === 'admin' || userLogued.username === 'Deyvis' || userLogued.username === 'Laura'
                                        res.forEach((r) => {
                                            if(!isLoginAdministrator) {
                                                if((r.username !== 'admin' && r.username !== 'Deyvis' && r.username !== 'Laura') || r.token.localeCompare(token) === 0) {
                                                    u.push(r);
                                                }
                                            } else {
                                                u.push(r)
                                            }
        
                                        })                          
                                    }
        
                                    setUsers(u);
                                    setNoHay(true);
                                });
                            }

                        });      

                    }

                }
            }
        }

    }, [borrado,client, id, history]);

    const cambiarActivacion = (user) => {

        if(user.disabled_at) {
            if(token){
                // Habilitando usuario            
                availableUser(token,
                    user.id,
                    null
                ).then((res)=>{
                    history.push('/users');
                    setBorrado(!borrado);
                });
            }

        } else {
            var today = new Date(),
            date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

            if(token){
                // Deshabilitando usuario
                availableUser(token,
                    user.id,
                    date          
                ).then((res)=>{
                    history.push('/users');
                    setBorrado(!borrado);
                });
            }  
        }
    }

    return(       
        <MasterLayout >
            {
                copy === 'admin' && noHay === true &&
                <MaterialTable
                    localization={{ body:{ emptyDataSourceMessage:<h1 style={{marginTop:'6%', position:'absolute', top:'16%', marginLeft:'-70px', textAlign:'center'}}></h1> } }}
                    icons={tableIcons}
                    title={t("users.users")}
                    columns={columns}  
                    data={users}
                    actions={[
                    {icon:Edit,
                    tooltip: `${t("users.editUser")}`, 
                    onClick:(e,data:any)=>history.push(`/updateUser/${data.id}`)
                    },     
                    {icon:AddBox,
                    tooltip: `${t("users.newUser")}`, 
                    onClick: (e,data:any)=>history.push(`/createUser`),
                    isFreeAction: true
                    },
                    rowData =>(
                        {icon:rowData.disabled_at ? ToggleOffOutlinedIcon : ToggleOnIcon,
                            tooltip: rowData.disabled_at ? `${t("users.activateUser")}` : `${t("users.disableUser")}`,
                            onClick:(e,data:any)=>{cambiarActivacion(data)}
                        }
                    )
                    ]}
                    options={{filtering:true, pageSizeOptions:[5,10,25,50], paginationType:"stepped", actionsColumnIndex: -1,toolbarButtonAlignment:"left",rowStyle:(data,index)=>data.disabled_at ? {color: "grey"}:null}}
                /> 
            }
            {
                copy === 'admin' && noHay === false &&
                <div style={{top:'50%',left:'50%,',display: 'flex',justifyContent: 'center', alignItems: 'center'}}>             
                    <Spinner color="primary" style={{width: 100, height: 100}}></Spinner>
                </div>   
            }
        </MasterLayout>       
    );
}


