import React, { useEffect } from "react";
import MonthPicker from "react-month-picker";
import "react-month-picker/scss/month-picker.scss";
import { RouteComponentProps } from "react-router-dom";


interface MonthPickerOwnProps {
    month?: any;
    setMonth?: any;
    resetMesFiltrado?: any
    show?: any
}


export const MonthPickerOwn: React.FC<MonthPickerOwnProps> = ({month, setMonth, resetMesFiltrado, show}: MonthPickerOwnProps) => {
            //prueba

  const [value, setValue] = React.useState(month);
  const monthPickerRef = React.useRef(null);

  useEffect(() => {
    if(show){
      monthPickerRef.current.show();
    }

  }, [])
          
  const lang = {
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    from: "From",
    to: "To"
  };

  const showPicker = () => {
    if (monthPickerRef && monthPickerRef.current) {
      monthPickerRef.current.show();
    }
  };
          
  const hidePicker = () => {
    if (monthPickerRef && monthPickerRef.current && !show) {
      monthPickerRef.current.dismiss();
    }
  };
          
  const handlePickerChange = (...args) => {
    setMonth({ year: args[0], month: args[1] })
    //setValue({ year: args[0], month: args[1] });
    if(!show){
      hidePicker(); // this works!
    }

  };
          
  const getMonthValue = () => {
      const month_res = month && month.month ? month.month : 0;
      const year_res = month && month.year ? month.year : 0;
  
      return month_res && year_res ? `${lang.months[month_res-1]} ${year_res}` : "Select Month";
    };

    
    return(
        <MonthPicker
            autoclose={false}
            lang={lang.months}
            ref={monthPickerRef}
            value={month}
            onChange={handlePickerChange}
            > 
            {
              !show && 
              <>
              <span onClick={showPicker}>{getMonthValue()}</span>
            

            <button onClick={resetMesFiltrado} style={{padding: 0, paddingLeft: 10, paddingBottom: 3}} type="button" className="btn close"
                data-dismiss="alert" aria-label="Close">
                 
                <span aria-hidden="true">×</span>
            </button>
            </>
            }
        </MonthPicker>
    )
}