import React, { useEffect, useState} from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import {  getData} from './requests/RequestMaker'
import {useTranslation} from 'react-i18next';
import 'react-tabs/style/react-tabs.css';
import { MasterLayout } from '../_metronic/layout/MasterLayout';
import TabNav from './Widgets/TabNavOwn';
import Tab from './Widgets/TabOwn';
import { primeraLetraMayuscula } from 'utils/Utils';
import { Markup } from 'interweave';
import { Spinner } from 'reactstrap';

interface InfoCollaboratorParams {
    id?: string;
    operator_id?: string;
}

interface InfoCollaboratorProps extends RouteComponentProps<InfoCollaboratorParams> {
    tipo?: string
}

export const InfoCollaborator: React.FC<InfoCollaboratorProps> = (props: InfoCollaboratorProps) => {

    let id = +props.match.params.id;
    let operator_id = +props.match.params.operator_id;
    const {tipo} = props

    const ES = 'es';
    const EN = 'en';

    const history = useHistory();
    let lang = window.navigator.language;
    lang = lang.substring(0,2);
    const initials = {
        descripcion: {
            es: "",
            en: ""
        },
        recomendaciones:  {
            es: "",
            en: ""
        },
        localizacion:  {
            es: "",
            en: ""
        },
        serviciosCercanos:  {
            es: "",
            en: ""
        },
        normas:  {
            es: "",
            en: ""
        },
        otros:  {
            es: "",
            en: ""
        },
        terminosCondiciones: {
            es: "",
            en: ""
        }
    }

    let dataweb = initials;
    let experiencia;
   // const [experiencia, setExperiencia] = useState<Experiencia>(undefined);
    const [collaboratorStretch, setCollaboratorStretch] = useState<any>();
    const [collaboratorPackage, setCollaboratorPackage] = useState<any>();
    const [noHay, setNoHay] = useState<boolean>(true);
    const [t,i18n] = useTranslation("global");

    const items_es = [
        {name: 'descripcion', required: false, placeholder: `Descripción Detallada`, type: 'text', readOnly: true},
        {name: 'recomendaciones', required: false, placeholder: `Recomendaciones`, type: 'password', readOnly: true},   
        {name: 'localizacion', required: false, placeholder:`Localización`, type: 'text', readOnly: true},   
        {name: 'serviciosCercanos', required: false, placeholder:`Servicios cercanos`, type: 'text', readOnly: true},   
        {name: 'normas', required: false, placeholder:`Normas`, type: 'text', readOnly: true},   
        {name: 'otros', required: false, placeholder:`Otros`, type: 'text', readOnly: true},  
        {name: 'terminosCondiciones', required: false, placeholder:`Términos y condiciones`, type: 'text', readOnly: true}, 
        {name: 'custom', required: false, placeholder:`custom`, type: 'text', readOnly: true}, 

    ]

    const items_en = [
        {name: 'descripcion', required: false, placeholder: `Detailed Description`, type: 'text', readOnly: true},
        {name: 'recomendaciones', required: false, placeholder: `Recommendations`, type: 'password', readOnly: true},   
        {name: 'localizacion', required: false, placeholder:`Location`, type: 'text', readOnly: true},   
        {name: 'serviciosCercanos', required: false, placeholder:`Nearby services`, type: 'text', readOnly: true},   
        {name: 'normas', required: false, placeholder:`Requirements`, type: 'text', readOnly: true},   
        {name: 'otros', required: false, placeholder:`Others`, type: 'text', readOnly: true},   
        {name: 'terminosCondiciones', required: false, placeholder:`Terms and conditions`, type: 'text', readOnly: true}, 
        {name: 'custom', required: false, placeholder:`custom`, type: 'text', readOnly: true}, 
    ]

    let idioma : any = null;
    idioma= sessionStorage.getItem('idioma');
    let token = sessionStorage.getItem('token');

    const [selected, setSelected] = useState<string>(lang === 'es' ? `${t("updateExperience.spanish")}` : `${t("updateExperience.english")}`);
    if(!token){
        token = '';
    }

    if(idioma == null){
        idioma='ESPAÑOL';
    }
    
    useEffect(() => {
  
          const storage = sessionStorage.getItem('sessionLogin');
          if(!storage) {
              history.push('/login');
          } else {
              if(!JSON.parse(storage)) {
                  history.push('/login');
              } else {
  
                  //useEffect
                  if(sessionStorage.getItem('idioma') == null){
                      idioma='ESPAÑOL';
                  }
                  if(idioma.localeCompare("INGLÉS") === 0){
                      i18n.changeLanguage("en");
                  }else if(idioma.localeCompare("FRANCÉS") === 0){
                      i18n.changeLanguage("fr");           
                  }else if(idioma.localeCompare("ESPAÑOL") === 0){
                      i18n.changeLanguage("es");         
                  }    
                  const token =  sessionStorage.getItem('token');
                  if(token){  
                      getData(token).then((res: any) => {
                          switch (tipo){
                            case 'stretch': const aux = res.collaboratorStretches.filter(t => t.id === id && t.operator_id === operator_id);
                                            setCollaboratorStretch(aux);setNoHay(false);
                                                break;
                            case 'package': setCollaboratorPackage(res.collaboratorPackets);setNoHay(false);
                                                break;
                            default: setCollaboratorPackage(undefined);
                          }
                      });
                  }
              }
          } 
      }, [tipo]);        
                

   switch (tipo){
    case 'stretch': dataweb = collaboratorStretch ? collaboratorStretch[0].dataweb.array : initials;
                    experiencia = collaboratorStretch ? collaboratorStretch[0] : undefined
                        break;
    case 'package': dataweb = collaboratorPackage ? collaboratorPackage[0].dataweb.array : initials;
                    experiencia = collaboratorPackage ? collaboratorPackage[0] : undefined
                        break;
    default: dataweb = initials;
  }
const images = []
if (experiencia && experiencia.dataweb) {
    for (let i=1; i<=15; i++) {
        if (experiencia.dataweb[`image${i}`]) {
            images.push(experiencia.dataweb[`image${i}`])
        }

    }
}


    return(
        
        <MasterLayout>
 
            <div className="container"> 
            {  !noHay && 
        <div className='col-xl-12'>
        <div className='card mb-2 mb-xl-5'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h1 className='fw-bolder m-0'>{t("updateExperience.experience")}: {experiencia ? experiencia.name : ''} </h1>
        </div>
      </div>
  
       
      <TabNav tabs={[`${t("updateExperience.spanish")}`, `${t("updateExperience.english")}`]} selected={selected} setSelected={setSelected }>
          
           {/* Pestaña Español */}
          <Tab isSelected={selected === `${t("updateExperience.spanish")}` }>
          <div id='kt_account_profile_details' className='collapse show'>
    
    <div className='card-body border-top p-9'>
    {  

        dataweb && items_es && items_es.map((item)=>{
          if(item.name === 'custom'){
            return( 
                <>
                    <h2 style={{color:'#0a3154', marginBottom: '35px'}}>{t("updateExperience.informationWeb")}</h2>
                    <Markup content={dataweb[item.name] ? (dataweb[item.name][ES] ?? '') : ''} />
                </>
            )
        }      
        return null  
      })                      
    }
      </div>
    </div>

          </Tab>

          {/* Pestaña Inglés */}
          <Tab isSelected={selected ===  `${t("updateExperience.english")}` }>
          <div id='kt_account_profile_details' className='collapse show'>
    
    <div className='card-body border-top p-9'>
    {  
       dataweb && items_en && items_en.map((item)=>{
        if(item.name === 'custom'){
            return( 
                <>
                    <h2 style={{color:'#0a3154', marginBottom: '35px'}}>{t("updateExperience.informationWeb")}</h2>
                    <Markup content={dataweb[item.name] ? (dataweb[item.name][EN] ?? '') : ''} />
                </>
            )
        }
        return null
      })                      
    }
      </div>

  </div>
          </Tab>
        </TabNav>

        <div className='card-body border-top p-9'>
            { images.length !== 0 &&
                <div className='row mb-6'>
                    <h2 style={{color:'#0a3154'}}>{t("updateExperience.experience's images")}:</h2>
                </div>
            }      
        <div className='row g-5 g-xl-8'>
              { 
             
                          images &&
                          images.map((im, index) =>{

                            if(im && im !== " "){                               
                                return(
                                    <>
                                        <div className='col-xl-2'>
                                            <img src={im} style={{margin: 10,padding: 10, width:200,height:150}}  alt="Ticando" /> 
                                        
                                        </div>
                                        </>                                  
                                );                                 
                            }else{
                                return(
                                    <div className='col-xl-2'>                                                          
                                                               
                                    </div>
                                  );
                            }                        
                          })
                      }
        </div>
        <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button  onClick={(e)=>{
                        history.push(`/viewCollaborator${primeraLetraMayuscula(tipo)}${tipo === 'stretch' ? 'es' : 's'}`);
                    }} className="btn btn-secondary" >{t("updateExperience.back")}</button>
        </div>
        </div>

        </div>
       </div>
}
    {
        noHay &&                                  
        <div style={{top:'50%',left:'50%,',display: 'flex',justifyContent: 'center', alignItems: 'center'}}>             
            <Spinner color="primary" style={{width: 100, height: 100}}></Spinner>
        </div>                                       
    } 
</div>
        </MasterLayout> 
    );

}