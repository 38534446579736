
import ReactDOM from 'react-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from 'react-i18next';
import i18next from 'i18next';
import global_es from './translations/es/global.json';
import global_en from './translations/en/global.json';
import global_fr from './translations/fr/global.json';


import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'
const {PUBLIC_URL} = process.env 
 
i18next.init({ 
  interpolation : {escapeValue: false},
  lng: "es",
  resources: {
    es: {
      global: global_es
    },
    en: { 
      global: global_en
    },
    fr : {
      global: global_fr
    }
  }
});

ReactDOM.render(

<I18nextProvider i18n={i18next}>
    <App basename={PUBLIC_URL} />
</I18nextProvider>,
document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
