import React, { useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {getClientByIDPC, updateClientTerms} from './requests/RequestMaker'
import {useTranslation} from 'react-i18next';
import 'react-tabs/style/react-tabs.css';
import {MasterLayout} from '../_metronic/layout/MasterLayout';
import Swal from 'sweetalert2';
import TabNav from './Widgets/TabNavOwn';
import Tab from './Widgets/TabOwn';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {EditorOwn} from './Widgets/EditorOwn';
import Terminos from './models/Terminos';
import draftToHtml from 'draftjs-to-html';
import Label from 'reactstrap/es/Label'
import {FormGroup, Input} from 'reactstrap'

export const TermsConditions: React.FC<any> = () => {

    const initials = {
        urlTerms: '',
        custom: {
            es: "<p>Estos son los términos y condiciones del cliente.</p>",
            en: "<p>These are the client's terms and conditions.</p>"
        },
    }
    const history = useHistory();
    let lang = window.navigator.language;
    lang = lang.substring(0, 2);

    const [t, i18n] = useTranslation("global");
    const [existe,] = useState<boolean>(false);
    const [idOpL,] = useState<number>(undefined);

    const [terminos, setTerminos] = useState<Terminos>(initials);
    const [cliente, setCliente] = useState<any>(null);

    let idioma: any = null;
    idioma = sessionStorage.getItem('idioma');
    let token = sessionStorage.getItem('token');

    const [selected, setSelected] = useState<string>(lang === 'es' ? `${t("updateExperience.spanish")}` : `${t("updateExperience.english")}`);
    const [loaded, setLoaded] = useState<boolean>(false);
    if (!token) {
        token = '';
    }

    if (idioma == null) {
        idioma = 'ESPAÑOL';
    }

    useEffect(() => {

        if (idioma.localeCompare("INGLÉS") === 0) {
            i18n.changeLanguage("en");
        } else if (idioma.localeCompare("FRANCÉS") === 0) {
            i18n.changeLanguage("fr");
        } else if (idioma.localeCompare("ESPAÑOL") === 0) {
            i18n.changeLanguage("es");
        }
        const storage = sessionStorage.getItem('sessionLogin');
        if (!storage) {
            history.push('/login');
        } else {
            if (!JSON.parse(storage)) {
                history.push('/login');
            } else {
                if (token) {

                    getClientByIDPC(token).then((res) => {
                        if (res.code === 456) {
                            Swal.fire({
                                icon: 'error',
                                title: `${t("main.invalidToken")}`,
                                text: `${t("main.invalidTokenmsg")}`,
                            })
                            history.push('/login')
                        } else {
                            setCliente(res[0]);
                            if (res[0].terms !== null) {
                                setTerminos(JSON.parse(res[0].terms))
                            }
                        }

                        setLoaded(true);
                    });
                }
            }
        }
    }, [existe]);
    
    const changeHandler = (name: any, value: any, lang: any) => {
        if (name === 'custom') {
            value = draftToHtml(value)
            setTerminos({
                ...terminos,
                ['custom']: {
                    ...terminos['custom'],
                    [lang]: value
                }})
        } else if (name === 'urlTerms') {
            setTerminos({
                ...terminos,
                urlTerms: value
            })
        }
        // console.log(terminos)
    }

    const updateClientHandler = () => {
        if (cliente) {
            if (token) {
                updateClientTerms(token,
                    terminos
                ).then((res) => {
                    if (res === 1) {
                        Swal.fire({
                            icon: 'success',
                            title: `${t("clientsData.success")}`,
                            text: `${t("clientsData.msgSuccess")}`,
                        })
                    } else if (res.code === 456) {
                        Swal.fire({
                            icon: 'error',
                            title: `${t("main.invalidToken")}`,
                            text: `${t("main.invalidTokenmsg")}`,
                        })
                        history.push('/login')
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: `${t("clientsData.error")}`,
                            text: `${t("clientsData.msgError")}`,
                        })
                    }
                });
            }
        }
    }

    return (

        <MasterLayout>

            <div className="container">
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bolder fs-3 mb-1'><h3>{t("main.termsConditions")}</h3></span>
                        <span className='text-muted fw-bold fs-7'></span>
                    </h3>
                    <div className='card-toolbar'>
                        {/* begin::Menu */}
                        {/* begin::Menu 1 */}
                        {/* end::Menu 1 */}
                        {/* end::Menu */}
                    </div>
                </div>
                <div className='col-xl-12'>
                    <div className='card mb-2 mb-xl-5'>
                        <div>
                            <FormGroup>
                                <Label for={'terms'}>{t('urlTerms')}</Label>
                                <Input 
                                    value={terminos.urlTerms}
                                    type={'text'} 
                                    name={'terms'} 
                                    id={'terms'} 
                                    placeholder={t('urlTerms')}
                                    onChange={(e) => {
                                        // @ts-ignore
                                        // @ts-ignore
                                        const value = e.nativeEvent.target.value
                                        changeHandler('urlTerms', value, null)
                                    }}
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <div className='card mb-2 mb-xl-5'>
                        <div style={{margin: '20px', marginTop: '0px'}}>
                            <div className='col-lg-2 fv-row'>

                            </div>
                        </div>

                        <TabNav tabs={[`${t("updateExperience.spanish")}`, `${t("updateExperience.english")}`]}
                                selected={selected} setSelected={setSelected}
                        >

                            {/* Pestaña Español */}
                            <Tab isSelected={selected === `${t("updateExperience.spanish")}`}>
                                <div id='kt_account_profile_details' className='collapse show'>

                                    <div className='card-body border-top p-9'>
                                        {loaded &&
                                        <EditorOwn nameField={'custom'} field={terminos.custom} onChange={changeHandler}
                                                   idioma={'es'}/>}

                                    </div>
                                </div>

                            </Tab>

                            {/* Pestaña Inglés */}
                            <Tab isSelected={selected === `${t("updateExperience.english")}`}>
                                <div id='kt_account_profile_details' className='collapse show'>

                                    <div className='card-body border-top p-9'>
                                        {loaded &&
                                        <EditorOwn nameField={'custom'} field={terminos.custom} onChange={changeHandler}
                                                   idioma={'en'}/>}

                                    </div>

                                </div>
                            </Tab>
                        </TabNav>
                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                            <button onClick={(e) => {
                                history.push(`/operationLine/${idOpL}`);
                            }} className="btn btn-secondary">{t("updateExperience.back")}</button>
                            <button type='submit' className='btn btn-primary'
                                    onClick={() => updateClientHandler()}>{t("clientsData.update")}</button>
                        </div>
                    </div>

                </div>
            </div>

        </MasterLayout>
    );

}