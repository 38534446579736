import React, { Suspense,useState } from 'react';
import './styles/App.css';
import { Routes } from './Routes';
import { BrowserRouter } from 'react-router-dom';
import {I18nextProvider} from 'react-i18next';
import i18next from 'i18next';
import { LayoutSplashScreen,  } from './_metronic/layout/core';
import { getDataLogin } from 'components/requests/RequestMaker';
import { MyGlobalContext } from 'utils/MyGlobalContext';
import Swal from 'sweetalert2';




type Props = {
    basename: string
}  
const  getRol = async ()=> 
{
    
    const token = sessionStorage.getItem('token');
    const login = sessionStorage.getItem('sessionLogin');

    let rol;
    if(login==="true" && token){
       await getDataLogin(token).then((res)=>{
           if(res.code === 456){
            return null
           }else{
            if(res[0]){
                rol = res[0].role; 
            }else{
                rol = sessionStorage.getItem('rol');
            }   
           }                            
           });   
    }
    return rol;
}
export const App: React.FC<Props> = ({basename}) => {
    let r;
    getRol().then((res)=>{
        r = res;
    })
    const [copy, setCopy] = useState<string>(r);

    return(
        <MyGlobalContext.Provider value= {{ copy, setCopy }}>
        <Suspense fallback={<LayoutSplashScreen />}>
        <BrowserRouter basename={basename}>
        <I18nextProvider i18n={i18next}>
            
                <Routes />           

            </I18nextProvider>
        </BrowserRouter>
        </Suspense>
        </MyGlobalContext.Provider>
    )
}
    
export default App

       


