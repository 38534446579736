import React, {useEffect} from 'react'
import {AsideDefault} from './components/aside/AsideDefault'
import {Content} from './components/Content'
import {useLocation} from 'react-router-dom'
import {MenuComponent} from '../assets/ts/components'
import { PageDataProvider } from './core/PageData'
import { HeaderWrapper } from './components/header/HeaderWrapper'
import { Footer } from './components/Footer'
import { WidgetLoader } from 'react-cloudinary-upload-widget';


const MasterLayout: React.FC = ({children}) => {
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  
    return (
      <PageDataProvider>
        <div className='page d-flex flex-row flex-column-fluid'>
          <AsideDefault />
          <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper />
          <div id='kt_content' >
              <div   id='kt_post'>
              <WidgetLoader />
                <Content>{children}</Content>
              </div>
          </div>
          <Footer/>
          </div>      
          </div>
          

      </PageDataProvider>
    )
  

}

export {MasterLayout}
