import React, { useEffect, useState} from 'react';
import { RouteComponentProps,useHistory } from 'react-router-dom';
import { Packets, Stretches } from '../@types/Data';
import { getData} from './requests/RequestMaker'
import { OperationLine} from '../@types/Data';
import {useTranslation} from 'react-i18next';
import { MasterLayout } from '../_metronic/layout/MasterLayout';
import { TableSchema } from './Widgets/tableSchema';
import Swal from 'sweetalert2';



interface MainPageParams {
    id: string;
}

type MainPageProps = RouteComponentProps<MainPageParams>;

export const MainPage: React.FC<MainPageProps> = (props: MainPageProps) => {

    const history = useHistory();
    const [operationLines, setOperationLines] = useState<OperationLine[]>();

    let stretches: Stretches[] = [];
    let packets: Packets[] = [];
    let contador = 0;
    let idioma : any = null;
    idioma= sessionStorage.getItem('idioma');
    if(idioma == null){
        idioma='ESPAÑOL';
    }
    const [t,i18n] = useTranslation("global"); 

    const columnas = [
        {class: "p-0 w-50px", content: ""},
        {class: "p-0 min-w-200px", content: '<span className="text-muted fw-bold fs-7">{t("experiences.experience´s name")}</span>'},
        {class: "p-0 min-w-40px", content: '<span className="text-muted fw-bold fs-7">{t("experiences.additional information")}</span>'},   
    ]

    useEffect(() => {
        idioma = sessionStorage.getItem('idioma');    
        if(idioma == null){
            idioma='ESPAÑOL';
        } 
        if(idioma.localeCompare("INGLÉS") === 0){
            i18n.changeLanguage("en");
        }else if(idioma.localeCompare("FRANCÉS") === 0){
            i18n.changeLanguage("fr");           
        }else if(idioma.localeCompare("ESPAÑOL") === 0){
            i18n.changeLanguage("es");         
        }
           const token =  sessionStorage.getItem('token');

           if(token){
             getData(token).then((res: any) => {
                 if(res.code === 456){
                    Swal.fire({
                        icon: 'error',
                        title: `${t("main.invalidToken")}`,
                        text: `${t("main.invalidTokenmsg")}`,
                      })      
                      history.push('/login') 
                 }
                if(res.operationLines){
                    res.operationLines.forEach((e:any) => {
                        e.stretches.forEach((s:any)=>{
                            stretches.push(s);
                        })
                    });
                    setOperationLines(res.operationLines.slice(0,5));
                    stretches = stretches.slice(0,5);
                }
             }); 
           }

        const storage = sessionStorage.getItem('sessionLogin');

        if(!storage) {
            history.push('/login');
        }

        idioma = sessionStorage.getItem('idioma');
        if(idioma === null){
            idioma='ESPAÑOL';
        }
    }, []);

    contador = 0;
    if(operationLines){
        operationLines.forEach((op)=>{
            if(contador <= 5){
                op.stretches.forEach((s)=>{                   
                    contador ++;
                    if(contador > 5){
                        return
                    }
                    stretches.push(s);
                 })
            }
            if(contador > 5){
                return
            }
        })
    }

    contador = 0;
    if(operationLines){
        operationLines.forEach((op)=>{
            if(contador <= 5){
                op.packets.forEach((p)=>{                   
                    contador ++;
                    if(contador > 5){
                        return
                    }
                    packets.push(p);
                 })
            }
            if(contador > 5){
                return
            }
        })
    }

 if(idioma == null){
    idioma='ESPAÑOL';
};

    return(
    <MasterLayout>
        <div className="row g-5 g-xl-8">
            <div className="col-xl-6">
                {
                    stretches.length > 0 && 
                    <TableSchema title={t('main.services')} subtitulo={`${t('main.displaying')} 5 ${t('main.services').toLowerCase()}` } className='card card-xl-stretch mb-xl-8' columnas={columnas} data={stretches}></TableSchema>   
                }
                {
                    stretches.length <= 0 && 
                    <TableSchema title={t('main.services')} subtitulo="" className='card card-xl-stretch mb-xl-8' columnas={columnas} data={stretches}></TableSchema>
                }
            </div>
            <div className="col-xl-6">
                {
                    packets.length > 0 && 
                    <TableSchema title={t('main.packages')} subtitulo={`${t('main.displaying')} 5 ${t('main.packages').toLowerCase()}` } className='card card-xl-stretch mb-xl-8'columnas={columnas} data={packets}></TableSchema>   

                }
                {
                    packets.length <= 0 &&
                    <TableSchema title={t('main.packages')} subtitulo="" className='card card-xl-stretch mb-xl-8'columnas={columnas} data={packets}></TableSchema>   

                }
            </div>
        </div>  
    </MasterLayout>     
        
    );
}
