/* eslint-disable jsx-a11y/anchor-is-valid */
import  { useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {toAbsoluteUrl} from '../_metronic/helpers'
import { RouteComponentProps, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2'
import { recoveryPassword } from './requests/RequestMaker'

const loginSchema = Yup.object().shape({
  password: Yup.string()
    .required('La contraseña es obligatoria.'),
})

const initialValues = {
  password: '',
}
interface RecoveryPasswordParams {
  token: string;
}

type RecoveryPasswordProps = RouteComponentProps<RecoveryPasswordParams>;

export const RecoveryPassword: React.FC<RecoveryPasswordProps> = (props: RecoveryPasswordProps) => {

  const token = props.match.params.token;
  
  const [loading, ] = useState(false);
  const history = useHistory();

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values:any, {setStatus, setSubmitting}) => {
      recoveryPassword(token,values.password).then(res=>{
        if(res === 1){
          Swal.fire({
            icon: 'success',
            title: 'Actualización correcta',
            text: 'La contraseña se ha actualizado correctamente.'
          })  
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Actualización incorrecta',
            text: 'Algo ha fallado en la actualización.'
          })
        }
        history.push(`/`);
        })
      }
  })



  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-cover bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/fond-3.jpg')})`,
        backgroundSize: 'cover',
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        <a href='#' className='mb-12'>
          <img alt='Logo' src="https://res.cloudinary.com/marketingpyme/image/upload/w_160,h_45,c_fill/logo_ticando.png" className='h-53px' />
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-lg-500px login rounded shadow-sm p-10 p-lg-15 mx-auto'>
        <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-light mb-3'>Actualización de contraseña</h1>
      </div>
      {/* begin::Heading */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fs-3 fw-normal text-light mb-0'>Nueva Contraseña</label>
            {/* end::Label */}
          </div>
        </div>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Actualizar</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>

    </form>
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
    </div>

  )
}



