/* eslint-disable jsx-a11y/anchor-is-valid */
import  { useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {toAbsoluteUrl} from '../_metronic/helpers'
import { RouteComponentProps, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2'
import { sendEmail } from './requests/RequestMaker'


const loginSchema = Yup.object().shape({
  client: Yup.string()
    .required('El cliente es obligatorio.'),
    user: Yup.string().required('El nombre de usuario es obligatorio.')
})

const initialValues = {
  client: '',
  user: ''
}
interface ForgottenPasswordParams {
  token: string;
}

type ForgottenPasswordProps = RouteComponentProps<ForgottenPasswordParams>;

export const ForgottenPassword: React.FC<ForgottenPasswordProps> = (props: ForgottenPasswordProps) => {

  
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values:any, {setStatus, setSubmitting}) => {

      sendEmail(values.client,values.user).then(res=>{
        if(res.code === 200){
          Swal.fire({
            icon: 'success',
            title: 'Correo enviado',
            text: 'Se ha enviado un correo a la dirección de email con la que se dio de alta el usuario para la actualización de la contraseña.',
            footer: 'Revise la bandeja de entrada.'
          })   
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Algo ha fallado, inténtelo más tarde.'
          })          
        }
      })

      }
  })



  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-cover bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/fond-3.jpg')})`,
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        <a href='#' className='mb-12'>
          <img alt='Logo' src="https://res.cloudinary.com/marketingpyme/image/upload/w_160,h_45,c_fill/logo_ticando.png" className='h-53px' />
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-lg-500px login rounded shadow-sm p-10 p-lg-15 mx-auto'>
        <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-light mb-3'>Ayuda de contraseña</h1>
      </div>

      <div className='text-center mb-10'>
        <label style={{color: 'white'}}>Introduzca el cliente y su nombre de usuario.</label>
      </div>    
      <div style={{marginLeft: '80%'}}>
        <a style={{color: '#90d4fb'}} href=''  onClick={()=>history.push('/login')}>Inicio Sesion</a>
      </div> 
      {/* begin::Heading */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-3 fw-normal text-light'>Cliente</label>
        <input
          placeholder=''
          {...formik.getFieldProps('client')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.client && formik.errors.client},
            {
              'is-valid': formik.touched.client && !formik.errors.client,
            }
          )}
          type='client'
          name='client'
          autoComplete='off'
        />
        {formik.touched.client && formik.errors.client && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.client}</span>
          </div>
        )}
      </div>     
      <div className='fv-row mb-10'>
        <label className='form-label fs-3 fw-normal text-light'>Usuario</label>
        <input
          placeholder=''
          {...formik.getFieldProps('user')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.user && formik.errors.user},
            {
              'is-valid': formik.touched.user && !formik.errors.user,
            }
          )}
          type='user'
          name='user'
          autoComplete='off'
        />
        </div>

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continuar</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>

    </form>
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
    </div>

  )
}



