import  { Component } from 'react';
import { EditorState, convertToRaw, ContentState} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';

export class EditorOwn extends Component<{field,onChange,idioma, nameField}, { editorState: string, contentState: any }> {
  constructor(props) {
    super(props);

    const contenido = this.props.field ? (this.props.field[this.props.idioma] ?? '' ): ''
    const contentBlock = htmlToDraft(contenido);

    let contentState;
    if (contentBlock) {
        contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    }
    this.state = {
      editorState: EditorState.createWithContent(contentState),
      contentState,
    };
  }

  onEditorStateChange: Function = (editorState) => {
      this.props.onChange(this.props.nameField,convertToRaw(editorState.getCurrentContent()),this.props.idioma)
    this.setState({
      editorState,
    });
  };

  render() {
    const { editorState, contentState } = this.state;
    return (
      <Editor 
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={this.onEditorStateChange}
        defaultEditorState={contentState}
      />
    )
  }
}

