/* eslint-disable jsx-a11y/anchor-is-valid */
import  { useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {toAbsoluteUrl} from '../_metronic/helpers'
import { getClienteID, getDataLogin, getLoginClientes } from './requests/RequestMaker'
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2'
import { useGlobalContext } from '../utils/MyGlobalContext';



const loginSchema = Yup.object().shape({
  client: Yup.string()  
  .required('El cliente es obligatorio.'),
  user: Yup.string()  
    .required('El usuario es obligatorio.'),
  password: Yup.string()
    .required('La contraseña es obligatoria.'),
})

const initialValues = {
  usuario: '',
  password: '',
}


export function LoginClients() {
  
  const [loading, ] = useState(false);
  const history = useHistory();
  const [show, setShow] = useState(false)
  const [, setLoginIncorrecto] = useState<boolean>(false);
  const [id,] = useState<string>('');
  const { setCopy } = useGlobalContext();
  
  //const [rolC,setRolC] = useContext<any>(RolContext);
 // const dispatch = useDispatch()
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values:any, {setStatus, setSubmitting}) => {
      getLoginClientes(values.client, values.user, values.password).then((res) => {
        setCopy('');  
        if(res.b) {
            sessionStorage.setItem('sessionLogin', "true");
            
             getClienteID(values.client, values.user, values.password).then((result) =>{
                 if(result != null){
                    sessionStorage.setItem('token',res.t);
                    getDataLogin(res.t).then((res)=>{
                      setCopy(res[0].role);     
                      if(res[0].role === 'admin'){
                        sessionStorage.setItem('cjluc','0');  
                      }else{
                        sessionStorage.setItem('cjluc','1'); 
                      }
                                      
                    });                   
                    history.push(`/`);
                 }                                  
            })  
        } else {

          if(res.msg === 'Usuario deshabilitado'){
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Este usuario está deshabilitado.',
              footer: 'Póngase en contacto con el administrador para la reactivación.'
            })     
          }else if(res.msg === 'Cliente deshabilitado'){
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'El cliente está desactivado',
              footer: 'Póngase en contacto con Civitrip para la reactivación.'
            })   
                
          }else{
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Datos incorrectos',
              footer: ''
            })
          }
            sessionStorage.setItem('sessionLogin', "false");
            setLoginIncorrecto(true);
            //setTimeout(()=>window.location.reload(), 4500);
        }
        if(id != null){
            sessionStorage.setItem('idCliente',id); 

        }
    })
    },
  })

  const changeShowPassword = () => {
    setShow(!show)
  }

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-cover bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/fond-3.jpg')})`,
        backgroundSize: 'cover',
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        <a href='#' className='mb-12'>
          <img alt='Logo' src="https://res.cloudinary.com/marketingpyme/image/upload/w_160,h_45,c_fill/logo_ticando.png" className='h-53px' />
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-lg-500px login rounded shadow-sm p-10 p-lg-15 mx-auto'>
        <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-light mb-3'>Acceso Panel de Cliente</h1>
      </div>
      {/* begin::Heading */}

      <div style={{marginLeft: '54%'}}>
        <a href='' style={{color: '#90d4fb'}} onClick={()=>history.push('/forgottenPassword')}> ¿Has olvidado la contraseña?</a>
      </div>
      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-3 fw-normal text-light'>Cliente</label>
        <input style={{padding: '0 10px 0'}}
          placeholder=''
          {...formik.getFieldProps('client')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.client && formik.errors.client},
            {
              'is-valid': formik.touched.client && !formik.errors.client,
            }
          )}
          type='client'
          name='client'
          autoComplete='off'
        />
        {formik.touched.client && formik.errors.client && (
          <div className='fv-plugins-message-container'>
            <span style={{color: '#90d4fb', marginTop: 5}} role='alert'>{formik.errors.client}</span>
          </div>
        )}
      </div>     
      <div className='fv-row mb-10'>
        <label className='form-label fs-3 fw-normal text-light'>Usuario</label>
        <input style={{padding: '0 10px 0'}}
          placeholder=''
          {...formik.getFieldProps('user')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.user && formik.errors.user},
            {
              'is-valid': formik.touched.user && !formik.errors.user,
            }
          )}
          type='user'
          name='user'
          autoComplete='off'
        />
        {formik.touched.user && formik.errors.user && (
          <div className='fv-plugins-message-container'>
            <span style={{color: '#90d4fb'}} role='alert'>{formik.errors.user}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fs-3 fw-normal text-light mb-0'>Contraseña</label>
            {/* end::Label */}
          </div>
        </div>
        <input style={{padding: '0 10px 0'}}
          type={show ? 'text' : 'password'}
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        <button type='button' style={{background: 'none', color: '#90d4fb', marginTop: 5, marginBottom: 4}} onClick={changeShowPassword}><i style={{marginRight: 5}} className={show ? "far fa-eye-slash" : "far fa-eye"} id="togglePassword"></i>{show ? 'Ocultar constraseña' : 'Mostrar contraseña'}</button>
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span style={{color: '#90d4fb'}} role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
        >
          {!loading && <span className='indicator-label'>Entrar</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>

    </form>
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
    </div>

  )
}



