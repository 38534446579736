import React, { useEffect, useState, forwardRef} from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { PanelControl } from '../@types/Data';
import { getDataLogin, getUsers, getClientByIDPC, getBookings, getReservasByClient } from './requests/RequestMaker'
import {useTranslation} from 'react-i18next';
import { MasterLayout } from '../_metronic/layout/MasterLayout';
import { useGlobalContext } from '../utils/MyGlobalContext';
import Swal from 'sweetalert2'
import MaterialTable, { Icons } from 'material-table';
import { AddBox, Check, Clear, DeleteOutline, ChevronRight, Edit, SaveAlt, FilterList, FirstPage, LastPage, Search, ChevronLeft, ArrowDownward, Remove, ViewColumn  } from '@material-ui/icons';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import { Spinner } from 'reactstrap';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable'
import { UserOptions } from 'jspdf-autotable';
import { MonthPickerOwn } from './Widgets/MonthPicker';

interface UsersParams {
    id: string;
}

type UsersProps = RouteComponentProps<UsersParams>;

export const Sales: React.FC<UsersProps> = (props: UsersProps) => {
    const [t,i18n] = useTranslation("global");

    const history = useHistory();
    const id = props.match.params.id; 
    const [client] = useState<PanelControl>();
    const [,setRole] = useState<string>("");
    const [,setUsers] = useState<any>([]);
    const [borrado] = useState<boolean>(false);
    const [noHay,setNoHay] = useState<boolean>(true);

    // Nuevo sales
    const [bookings, setBookings] = useState<any>();
    const [bookings_filtrado, setBookings_filtrado] = useState<any>();
    const [month, setMonth] = useState({ year: 0, month: 0 })

    const { copy } = useGlobalContext();
    let idioma : any = null;
    idioma= sessionStorage.getItem('idioma');
    if(idioma == null){
        idioma='ESPAÑOL';
    }
    let token = sessionStorage.getItem('token');
    if(!token){
        token = '';
    }


    const tableIcons: Icons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
      };
      
      const headers = [
        {title:"UUID", field: "uuid"},
        {title:"Nombre y Apellidos", field: "Nombre"},
        {title:"Teléfono", field: "Telefono"},
        {title:"Email", field: "Email"},
        {title:"Total", field: "Total"},
        {title:"Fecha Compra", field: "fechaCompra"},
        {title: "Tickets\n\n  Número || Reserva || Fecha reservada", field: "items"}
    ]

      const columns = [
        {title:"UUID", field: "uuid"},
        {title:"Nombre", field: "Nombre"},
        {title:"Apellidos", field: "Apellidos"},
        {title:"Teléfono", field: "Telefono"},
        {title:"Email", field: "Email"},
        {title:"Total", field: "Total",render:(rowData)=>{
            return(
                <div>
                    {rowData.Total} {rowData.money}
                </div>
            )
        }},
        {title:"Fecha Compra", field: "paid_at", render:(rowData)=>{
            if(rowData.paid_at !== "0000-00-00 00:00:00"){
                let paid_at = new Date(rowData.paid_at)
                let minutes = paid_at.getMinutes() < 10 ? '0'+paid_at.getMinutes() : paid_at.getMinutes()
                let seconds = paid_at.getSeconds() < 10 ? '0'+paid_at.getSeconds() : paid_at.getSeconds()
                return(
                    <div>
                        {paid_at.getDate()}-{paid_at.getMonth() + 1}-{paid_at.getFullYear()} {paid_at.getHours()}:{minutes}:{seconds}
                    </div>
                )
            } else {
                return(
                    <div>
                        -
                    </div>
                )
            }    
        }
        },
        //\nNombre    Fecha Compra    Fecha Reservada    Cantidad reservada    Código
    ]
    useEffect(() => {

        i18n.changeLanguage("es");  
        if(idioma.localeCompare("INGLÉS") === 0){
            i18n.changeLanguage("en");
        }else if(idioma.localeCompare("FRANCÉS") === 0){
            i18n.changeLanguage("fr");           
        }else if(idioma.localeCompare("ESPAÑOL") === 0){
            i18n.changeLanguage("es");         
        }

        const storage = sessionStorage.getItem('sessionLogin');
        if(!storage) {
            history.push('/login');
        } else {
            if(!JSON.parse(storage)) {
                history.push('/login');
            } else {
                if(!client) {
                    const token = sessionStorage.getItem('token');
                    if(token){  
                        getDataLogin(token).then((res)=>{

                            setRole(res[0].role);
                            if(res[0].role.localeCompare('admin') !== 0){
                                history.push('/');
                            }else{
                                if(!bookings){
                                    getClientByIDPC(res[0].token).then(r => {
                                        if(res.code === 456) {
                                            Swal.fire({
                                                icon: 'error',
                                                title: `${t("main.invalidToken")}`,
                                                text: `${t("main.invalidTokenmsg")}`,
                                              })      
                                              setTimeout(()=>history.push('/login'), 4500); 
                                        }
                                        getReservasByClient(token).then(res => {
                                            setBookings(res)
                                            setBookings_filtrado(res)
                                        })
                                        //getBookings(r[0].PRIVATEAPIKEY).then(bookings => setBookings(bookings))
                                    })
                                }
                            }                           
                        });      
                        
                       /* getUsers(token).then((res)=>{
                            const u:any=[];
                            res.forEach((r)=>{
                                if(r.token.localeCompare(token)!==0){
                                    u.push(r);
                                }
                            })
                            setUsers(u);
                            setNoHay(true);
                        }); */
                    }
                }
            }
        }
    }, [borrado,client, id, history]);

    useEffect(() => {
        if(bookings && month.year !== 0){
            const mes_filtrado = new Date(month.year,month.month-1)
            const bookings_filtrados = bookings.filter(booking => {
                let mes;
                if(booking.paid_at !== "0000-00-00 00:00:00"){
                    mes = new Date(booking.paid_at).getMonth() + 1
                    return mes === month.month
                }

            })
            setBookings_filtrado(bookings_filtrados)
        }
    },[month])

    const downloadExcel = () => {
        let workSheet = XLSX.utils.json_to_sheet([],{header: ["uuid", "Nombre", "Apellidos", "Teléfono", "Email", "Total", "Moneda", "Fecha de compra", "Servicio", "Fecha reservada", "Número"]});
        let fila = 2;
        bookings.map(r=>{
            delete r.id
            delete r.tableData
            delete r.fechaCompra
            delete r.Cliente
           
            XLSX.utils.sheet_add_json(workSheet,[r], {skipHeader: true, origin: { r: fila, c: 0 }});
            r.items.map(i=>{
                fila = fila + 1;
                delete i.id
                delete i.id_reserva
                delete i.cantidad
                delete i.created_at
                XLSX.utils.sheet_add_json(workSheet, [i],{skipHeader: true, origin: { r: fila, c: 8 }});
            })
            fila = fila + 1;
        })
        const workBook  = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook,workSheet,"Reservas")
        //Buffer
        let buf = XLSX.write(workBook,{bookType:"xlsx",type:"buffer"})
        //Binary string
        XLSX.write(workBook,{bookType:"xlsx",type:"binary"})
        //Download
       XLSX.writeFile(workBook,"Reservas.xlsx")
    }

    interface jsPDFWithPlugin extends jsPDF {
        autoTable: (options: UserOptions) => jsPDF;
      }
      
    const downloadPdf = () => {

        let reser: any = [];
        bookings_filtrado.map((r)=>{
            let it: string = "";
            r.items.map((i)=>{
                let fecha = formatoFecha(i.datebook)
                it = it + i.numero + " || " + i.name + " || " + fecha + "\n";
            })
            let fecha = '-'
            if(r.paid_at !== "0000-00-00 00:00:00") {
                let paid_at = new Date(r.paid_at)
                let minutes = paid_at.getMinutes()
                let mins = minutes.toString()
                if(minutes < 10){
                    mins = '0'+minutes
                }
                let seconds = paid_at.getSeconds()
                let s = seconds.toString()
                if(seconds < 10 ) {
                    s = '0'+seconds
                }
                fecha =  `${paid_at.getDate()}-${paid_at.getMonth() + 1}-${paid_at.getFullYear()} ${paid_at.getHours()}:${mins}:${seconds}`

            }
            reser.push({uuid: r.uuid, Nombre: `${r.Nombre} ${r.Apellidos}`, Telefono: r.Telefono, Email: r.Email, Total: `${r.Total} ${r.money}`, fechaCompra: fecha, items: it})
        })
        const doc = new jsPDF() as jsPDFWithPlugin;
        doc.autoTable({
            columns: headers.map(col=>({...col,dataKey:col.field})),
            body: reser,
            styles: {
                fontSize: 5
            }
        })
        doc.text("Ventas",20,10);

        doc.save("Ventas.pdf"); 
    }

    const resetMesFiltrado = () => {
        setMonth({ year: 0, month: 0 })
        setBookings_filtrado(bookings)
    }

    return(       
        <MasterLayout >
            {
                !bookings &&
                <div style={{top:'50%',left:'50%,',display: 'flex',justifyContent: 'center', alignItems: 'center'}}>             
                    <Spinner color="primary" style={{width: 100, height: 100}}></Spinner>
                </div>  
            }
            {
                copy === 'admin' && noHay === true && bookings &&
                <>
                    <div className="container">
                        <div className="row justify-content-start">
                            <div className="col-2" style={{width: 130}}><h4>Filtrar por mes: </h4></div>
                            <div className="col-2"><MonthPickerOwn month={month} setMonth={setMonth} resetMesFiltrado={resetMesFiltrado}></MonthPickerOwn></div>
                        </div>           
                    </div>

                    <MaterialTable
                    localization={{ body:{ emptyDataSourceMessage:<h1 style={{marginTop:'6%', position:'absolute', top:'16%', marginLeft:'-70px', textAlign:'center'}}></h1> } }}
                    icons={tableIcons}
                    title={t("main.sales")}
                    columns={columns}  
                    data={bookings_filtrado}
                    options={{filtering:true, pageSizeOptions:[5,10,25,50,100], paginationType:"stepped",
                    rowStyle:(data,index)=>index % 2 === 0 ? {background: "#e8e8e8"}:null}}
                    actions={[
                        {icon:SaveAlt,
                        tooltip:"Exportar a Excel",
                        onClick:()=>downloadExcel(),
                        isFreeAction:true},
                        {icon:PictureAsPdfOutlinedIcon,
                        tooltip: "Exportar a PDF",
                        onClick:()=>downloadPdf(),
                        isFreeAction:true}
                    ]}
                    detailPanel={rowData => {
                        
                        return (
                            <Table size="small" aria-label="purchases">
                            <TableHead>
                                <TableRow>
                                <StyledTableCell align='center'>Número</StyledTableCell>
                                <StyledTableCell align='center'>Servicio</StyledTableCell>
                                <StyledTableCell align='center'>Fecha reserva</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rowData.items.map((item) => {
                                const datebook = new Date(item.datebook)
                                const dia = datebook.getDate()
                                const mes = datebook.getMonth() + 1
                                const año = datebook.getFullYear()
                                const hora = datebook.getUTCHours()
                                let minutes = datebook.getUTCMinutes()
                                let mins = minutes.toString()
                                if(minutes < 10) {
                                    mins = '0' + minutes.toString()
                                }
                                let fecha;
                                if(hora === 0){
                                    fecha = `${dia}-${mes}-${año}`
                                } else {
                                    fecha = `${dia}-${mes}-${año} ${hora}:${mins}h`
                                }
                                return(
                                    <TableRow key={item.numero}>
                                    <TableCell align='center' component="th" scope="row">
                                        {item.numero}
                                    </TableCell>
                                    <TableCell align='center'>{item.name}</TableCell>
                                    <TableCell align='center'>{fecha}</TableCell>
                                    </TableRow>
                                )
                                })}
                            </TableBody>
                            </Table>
                        )
                        }}
                        onRowClick={(event, rowData, togglePanel) => togglePanel()}
                    > 
                    </MaterialTable> 
                </>
            }
            {
                copy === 'admin' && noHay === false && bookings &&
                <div style={{top:'50%',left:'50%,',display: 'flex',justifyContent: 'center', alignItems: 'center'}}>             
                <Spinner color="primary" style={{width: 100, height: 100}}></Spinner>
                </div>   
            }
        </MasterLayout>       
    );
}


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

function formatoFecha(fecha: any) {
    if(fecha !== "0000-00-00 00:00:00"){
        const datebook = new Date(fecha)
        const dia = datebook.getDate()
        const mes = datebook.getMonth()
        const año = datebook.getFullYear()
        const hora = datebook.getUTCHours()
        let minutes = datebook.getUTCMinutes()
        let mins = minutes.toString()
        if(minutes < 10){
          mins = '0'+minutes
        }
        let res;
        if(hora === 0){
            res = `${dia}-${mes + 1}-${año}`
        } else {
            res = `${dia}-${mes + 1}-${año} ${hora}:${mins}h`
        }
        return res
      } else {
        return ""
      }
}
